/*
* 업무구분: 신탁>영업>변경/해지/이체>신탁출금
* 화 면 명: MSPTS208M
* 화면설명: 신탁출금 [2010]
* 접근권한: 
* 작 성 일: 2023.02.09
* 작 성 자: 지재호
* 파 일 명: TFIO42100.xfdl
*/
<template>
  <ur-page-container
    class="fts"
    :show-title="false"
    title="신탁출금"
    :topButton="true"
  >
    <ts-header ref="tsHeader" :propObj="pHeaderObj"></ts-header>

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field class="w130 input-account"
                    numeric
                    mask="#######-###"
                    @click="[removeError($event.currentTarget)]"
                    @keyup="Edt_acno_onkeyup($event, 'Edt_acno')"
                    v-model="Edt_acno"
                    :class="{'comp': Edt_acno.length > 9}"
                    ref="Edt_acno"
                  />
                  <mo-dropdown
                    class="w200"
                    :items="list_Cbo_accSeq"
                    v-model="Cbo_accSeq"
                    placeholder=" "
                  />
                  <mo-button class="btn-pop-download" @click="fn_OpenPopup(0)" >
                  </mo-button>
                  <mo-text-field
                    class="w130"
                    v-model="edt_cnm"
                    ref="edt_cnm"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p">
                <div class="wrap-input row">
                  <label class="emphasis"> 비밀번호 </label>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    numeric
                    class="w80"
                    v-model="edt_pwd"
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"         
                    ref="edt_pwd"
                  />
                  <mo-text-field class="w130"
                    v-else
                    type="password"
                    maxlength="4"
                    v-model="edt_pwd"
                    @keyup="pwd_onkeyup($event, 'edt_pwd')"
                    ref="edt_pwd"
                  />
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button 
                    @click="fn_init"
                    :disabled="enabledList.btn_init==false"
                  > 초기화 </mo-button>
                  <mo-button primary 
                    @click="fn_searchList"
                    :disabled="enabledList.btn_searchList==false"
                  > 조회 </mo-button>
                  <mo-button primary size="small" v-if="lv_serverType==='local'" @click="fn_test">라우트테스트</mo-button>          
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-button result">
          <!-- <mo-button> 책임자승인번호초기화 </mo-button> -->
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title mt-0">
          <h2 class="table-title">출금금액</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span> 출금가능금액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" 
                      mask="number" 
                      numeric
                      v-model="edt_payAbleAmt"
                    disabled />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  <span> 현금지급액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric disabled
                      v-model="edt_cashAmt"
                     />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr></tr>
              <tr>
                <th>
                  <span> 수표지급액 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field class="w270" mask="number" numeric disabled 
                      v-model="edt_chkAmt"
                    />
                    <mo-text-field class="w130" mask="number" numeric disabled
                      v-model="edt_ChkNos"
                    /> 매
                    <mo-button class="btn-pop-download" disabled> </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 브릿지대체금액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric
                      v-model="edt_brigIamtAmt"
                    disabled />
                  </div>
                </td>
                <th>
                  <span> 보수출금액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric disabled 
                      v-model="edt_bosuAmt"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 은행이체금액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric disabled 
                      v-model="edt_rsrvAmt"
                    />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  <span> 현물출고액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric disabled 
                      v-model="edt_rthgTmtlAmt"
                    />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  <span> 출금총액 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field class="input-long" mask="number" numeric disabled 
                      v-model="edt_trAmt"
                    />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">은행이체</h2>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w50"> </th>
                <th class="w50"> 삭제 </th>
                <th>수익자</th>
                <th>출금금액</th>
                <th colspan="4">송금계좌</th>
                <th>이체요청금액</th>
                <th>브릿지입금액</th>
                <th>브릿지계좌번호</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if=" $tsUtil.gfn_isNull($tsUtil.gfn_trim(ds_list.cnm)) == false" :class="'checked'">
                <td>
                  <mo-radio :checked="true"></mo-radio>
                </td>
                <td>
                  <mo-button @click="grd_list_oncellclick('delete')" class="btn-pop-minus"> </mo-button>
                </td>
                <td>
                  {{ ds_list.cnm }}
                </td>
                <td class="aR">
                  {{$bizUtil.numberWithCommas(Number(ds_list.oamtAmt00))}}
                </td>
                <td>
                  {{ ds_list.trBkcdNm }}
                </td>
                <td>
                  {{ ds_list.bnkAcno }}
                </td>
                <td class="w50">
                  <mo-button
                    class="btn-pop-download"
                    @click="grd_list_oncellclick('select')" 
                  >
                  </mo-button>
                </td>
                <td>
                  {{ ds_list.bnkAccNm }}
                </td>
                <!-- <td class="aR">
                  {{ ds_list.bnkAccNm }}bnkAccNm
                </td> -->
                <td class="aR">
                  <mo-text-field class="w110" mask="number" numeric v-model="ds_list.ftrnAmt" /> 
                </td>
                <td class="aR">
                  <mo-text-field class="w110" mask="number" numeric v-model="ds_list.brigIamtAmt" />
                </td>
                <td>
                  {{ ds_list.bridgeAcno }}
                </td>
                
              </tr> 
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_confirm">
            확인
          </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    
    <!-- popup321 송금계좌등록내역조회 -->
    <msp-ts-321p
      ref="popup321"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup321Obj"
    ></msp-ts-321p>

    <!-- popup106 책임자 승인 -->
    <msp-ts-106p
      ref="popup106"
      @ts-popup106-callback="fn_Popup_CallBack_106"
      @ts-alert-popup="fn_AlertPopup"
      :popupObj="pPopup106Obj"
    ></msp-ts-106p>

     <!-- popup206 계좌번호 조회 --> 
    <msp-ts-206p
      ref="popup206"
      @ts-popup206-callback="fn_Popup_CallBack_206"      
      :popupObj="pPopup206Obj"
    ></msp-ts-206p>
    
  </ur-page-container>
</template>
<script>
const defaultDsSearch = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  procDate: "",
  pwd: "",
  csId: "",
  msUseYn: "",
  msData: "",
  aprvNo: "",
  chmnAprvNo: "",
  trAmt: "",
  offrSeq: "",
  payAbleAmt: "",
  transAprvNo: "",
  procBrcd: "",
  rmkC: "",
  cashAmt: "",
  pwdYn: "",
};

const defaultDsList = {
  cnm: "",
  brigCpstAcno: "",
  brigBnkbSeq: "",
  brigAccSeq: "",
  oamtAmt: "",
  bnfcCsId: "",
  trBkcd: "",
  bnkAcno: "",
  bnkAccNm: "",
  trBkcdNm: "",
  ftrnAmt: "",
  brigIamtAmt: "",
  ftrnProsResult: "",
  gramProcStat: "",
  bridgeAcno: "",
  oamtAmt00: "",
  rthgPymYn: "",
  rthgTmtlTcurAmt: "",
  stipSeq: "",
};

const defaultDsDetail = {
  aprvNo: "",
  payAbleAmt: "",
  offrSeq: "",
  rmkC: "",
  basFeeWcurAmt: "",
  teleVssTc: "",
};

const defaultDsInsert = {
  chmnAprvNo: "",
  transAprvNo: "",
};

const defaultDsTfgoal = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  trDate: "",
  trno: "",
  trSeq: "",
  oamtAcno: "",
  bkcd: "",
  bnkAcno: "",
  bnkAccDepoNm: "",
  bktrTrAmt: "",
  orglGramNo: "",
  procErrC: "",
  gramProcStat: "",
  canYn: "",
  bnkNm: "",
  bnfcNm: "",
  bnfcRcno: "",
  contMnNm: "",
  contMnRcno: "",
  depoNm: "",
  deporRcno: "",
  pymTamt: "",
  procDofOrgNo: "",
  procBrcd: "",
  rnpRecpIdCntn: "",
  rstMsg: "",
  rstVal: "",
  trstTrTc: "",
  accChckGramId: "",
};

const defaultDsPbpr = {
  pbprTp: "",
  pbprCntl: "",
  pbprPageNo: "",
  pbprLineNo: "",
  pbprOutLen: "",
  pbprData: "",
  pbprPrtCnt: "",
};

const defaultDsList00 = {
  cnm: "",
  brigCpstAcno: "",
  brigBnkbSeq: "",
  brigAccSeq: "",
  oamtAmt: "",
  bnfcCsId: "",
  trBkcd: "",
  bnkAcno: "",
  bnkAccNm: "",
  trBkcdNm: "",
  ftrnAmt: "",
  brigIamtAmt: "",
  ftrnProsResult: "",
  gramProcStat: "",
  bridgeAcno: "",
  oamtAmt00: "",
  rthgPymYn: "",
  rthgTmtlTcurAmt: "",
};

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil";
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil";
import TSHeader from "~/src/ui/ts/comm/TSHeader"; // header 영역 (공통)
import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"; // Alert 팝업 (공통)
import MSPTS321P from "~/src/ui/ts/MSPTS321P"; // 송금계좌등록내역조회
import MSPTS106P from "~/src/ui/ts/MSPTS106P"; // 계좌번호 조회
import MSPTS206P from "~/src/ui/ts/MSPTS206P"; // 계좌번호 조회
import { TSSearchMixin } from "~/src/ui/ts/comm/TSSearchMixin"; // 계좌번호 조회
import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'  
import tsUtil from '~/src/config/utils/tsUtil';

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS208M",
  mixins: [TSSearchMixin],
  screenId: "MSPTS208M",
  components: {
    "ts-header": TSHeader,
    "ts-alert-popup": TSAlertPopup,
    "msp-ts-321p": MSPTS321P,
    "msp-ts-106p": MSPTS106P,
    "msp-ts-206p": MSPTS206P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.lv_serverType = process.env.NODE_ENV      
    this.$nextTick(()=>{        
        if ( !TSCommUtil.gfn_isNull(this.$route.params.acno)) {
          let tacno = this.$route.params.acno
          let accSeq = this.$route.params.accSeq
          // this.Edt_acno = tacno // tacno + bnkbSeq
          // this.Cbo_accSeq = accSeq
          this._setValue(tacno, accSeq, "11", "", "Y");
          
          this.fn_transaction();    
          //자동조회
          // this.fn_searchList()
        }
    })
  },
  mounted() {
    this.fn_comCode();
    this.fn_afterFormOnload();
    this.fn_init(); // TODO: 운영시 반영해야함! fn_init
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {          
        title: '신탁출금',
      },
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model
      lv_isProcess: this.getStore('tsStore').getters.getState.isProcess,

      // 보안키패드 관련
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false,  // 비밀번호 보안키패드 초기화 
      lv_masked_val: '',  // 비밀번호 보안키패드 치환값 ****

      // 팝업 데이터 객체
      pAlertPopupObj: {}, // 공통 객체
      pPopup321Obj: {},   // 송금계좌등록내역조회
      pPopup106Obj: {},   // 승인자 요청
      pPopup206Obj: {},   // 계좌번호 조회
      lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data, // 로그인 사용자 정보
      lv_serverType : '',

      acctCnt: 0, // 계좌 건수
      gArs_pswn_yn: "N", //2021-08-05 add ARS컨트롤 비밀번호 결과값 : 비밀번호 맞을 때 Y

      ds_search: Object.assign({}, defaultDsSearch),
      ds_list: Object.assign({}, defaultDsList),
      ds_detail: Object.assign({}, defaultDsDetail),
      ds_insert: Object.assign({}, defaultDsInsert),
      ds_tfgoal: [],//Object.assign({}, defaultDsTfgoal), 
      //ds_tfgoal00: Object.assign({}, defaultDsTfgoal),
      //ds_tfgoal01: Object.assign({}, defaultDsTfgoal),
      ds_pbpr: [], //Object.assign({}, defaultDsPbpr),
      ds_pbpr00: [], // Object.assign({}, defaultDsPbpr),
      ds_list00: Object.assign({}, defaultDsList00),
      ds_cmb0080: [],         // 대표번호 공통코드
      list_Cbo_accSeq: [],
      Cbo_accSeq: "",

      cal_bizDate: "",
      edt_chmnAprvNo: "",
      edt_transAprvNo: "",

      Edt_acno: "", 
      edt_cnm: '',
      edt_pwd: '',

      Static48: '',           // 사용 안할듯

      enabledList: {
        edt_cashAmt: '',
        grd_list: '',
      },
      
      edt_cashAmt: '',        // 현금지금액
      edt_chkAmt: '',         // 수표지급액
      edt_ChkNos: '',         // 매 (수표)
      edt_brigIamtAmt: '',    // 브릿지대체금액
      edt_rsrvAmt: '',        // 은행이체금액
      edt_rthgTmtlAmt: '',    // 현물출고액
      edt_trAmt: '',          // 출금총액
      edt_bosuAmt: '',        // 보수출금액
      rdo_regTeleTypeYn: '2', // 창구(일반) 디폴트값으로 설정
      edt_rthgPymYn: '',      // 실물여부
      
      // ds_detail
      edt_aprvNo: '' ,        // 승인신청 번호
      edt_payAbleAmt: '',     // 출금가능금액
      edt_offrSeq: '',        // 계약승인번호
      edt_rmkC: '',           

      callBackBtnState: '',
      nextFunc: '',          // TSInfoUtil.commReportInfo 에 콜백함수
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    isProcessCheck() {
      return this.getStore('tsStore').getters.getState.isProcess
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SetMaskedTxt
    * 설명       : 보안키패드 체크
    ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val        
      if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
        this.$refs['edt_pwd'].$el.classList.remove('error')
    }, 

    /******************************************************************************
     * Function명 : removeError
     * 설명       : css error 삭제
     ******************************************************************************/
    removeError (target) {
      
      // 현재 뎁스만 param 으로 넘겨받아 처리
      // target.classList.remove('error')

      // 부모 뎁스 까지 확인
      if ( target.parentElement.parentElement.classList.contains('error') ) {
        target.parentElement.parentElement.classList.remove('error')
      }
      else if ( target.parentElement.classList.contains('error') ) {
        target.parentElement.classList.remove('error')
      }
      else {
        target.classList.remove('error')
      }
    },
    
    /******************************************************************************
     * Function명 : fn_addValidErr
     * 설명       : 에러 클래스 추가
    ******************************************************************************/      
    fn_addValidErr (lv_el) {
      if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
        lv_el.classList.add('error')
      }
      lv_el.focus()
    },

    /***************************************************************************************
      출금 은행 삭제
    *****************************************************************************************/
    fn_delete ( row ) {
      console.log(row)
    },

    /***************************************************************************************
      실질적인 화면 초기화 
    *****************************************************************************************/
    fn_afterFormOnload() {
      // 공통코드
      // this.fn_comCode(); /* 공통코드 호출 : 대표번호 */

      // this.Div01.div_acno._setPassbookUseYn("Y"); // 통발기 사용여부

      this._setValue("", "", "11", "", "Y");
      this.edt_chmnAprvNo = "";
      // 비밀번호 사용시 미리 폰패드 오픈
      // this.Div01.div_acno.div_device.fn_phonePadOpen();
    },

    /***************************************************************************************
        초기화 
    *****************************************************************************************/
    fn_init() {
      Object.assign(this.ds_search, defaultDsSearch)
      Object.assign(this.ds_list, defaultDsList)
      Object.assign(this.ds_detail, defaultDsDetail)
      Object.assign(this.ds_insert, defaultDsInsert)
      this.ds_pbpr = [], // Object.assign(this.ds_pbpr, defaultDsPbpr)
      this.ds_pbpr00 = [], // Object.assign(this.ds_pbpr00, defaultDsPbpr)
      this.ds_tfgoal = [], //Object.assign(this.ds_tfgoal, defaultDsTfgoal)
      this.list_Cbo_accSeq = []
      // Object.assign(this.ds_tfgoal00, defaultDsTfgoal)
      // Object.assign(this.ds_tfgoal01, defaultDsTfgoal)
      this.Cbo_accSeq = "";
      this.cal_bizDate = this.lv_basInfo.busyDate
      this.Edt_acno = ""
      this.edt_cnm = ""
      this.edt_chmnAprvNo = ''
      this.gArs_pswn_yn = "N";   // 2021-08-05 add
      this.edt_payAbleAmt = '0'  // 출금가능금액
      this.edt_cashAmt = '0'     // 현급지금액
      this.edt_chkAmt = '0'      // 수표지급액
      this.edt_ChkNos = '0'      // 수표 매
      this.edt_brigIamtAmt = '0' // 브릿지대체금액
      this.edt_bosuAmt = '0'     // 보수출금액
      this.edt_rsrvAmt = '0'     // 은행이체금액
      this.edt_rthgTmtlAmt = '0' //현물출고액
      this.edt_trAmt = '0'       // 출금총액
      this.edt_pwd = ''
      this.lv_isClear = !this.lv_isClear ? true : false // 비밀번호 보안키패드 초기화
      this.fn_rmAllErrCss() // error class 전체 삭제
    },

    /************************************************************************************************
     * 종합계좌컨트롤 조회 후 처리
     ************************************************************************************************/
    acctCallBackFuncTacno() {
      this.Div01.edt_pwd.set_value("");
      this.gArs_pswn_yn = "N"; // 2021-08-05 add 계좌조회 후 ARS컴포넌트 초기화
    },

    /***************************************************************************************
      출금가능금액과 수익자 계좌 조회
    *****************************************************************************************/
    fn_searchList () {
      if (TSCommUtil.gfn_isNull(this._getValue())) {
        TSCommUtil.fn_validate( this, [{ content: '계좌번호를 입력하십시오.', ref:'Edt_acno' }] )
        return false;
      }
      if (this.gArs_pswn_yn == "N") {
        //2021-08-05 add 전역변수 패스워드 체크
        if (TSCommUtil.gfn_isNull(this.edt_pwd)) {
          TSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시오.', ref:'edt_pwd' }] )
          return false;
        }
      }

      //조회용 dataset을 초기화 해준다.
      Object.assign(this.ds_search, defaultDsSearch)
      Object.assign(this.ds_list, defaultDsList)
      Object.assign(this.ds_detail, defaultDsDetail)
      Object.assign(this.ds_insert, defaultDsInsert)

      //조회용 dataset에 조회할 항목을 넣어준다.
      this.ds_search.tacno = this._getTacnoValue()
      this.ds_search.bnkbSeq = this._getBnkbSeqValue()
      this.ds_search.accSeq = this._getAccSeqValue()

      this.ds_search.procDate = this.cal_bizDate
      this.ds_search.pwd = this.edt_pwd
      this.ds_search.pwdYn = this.gArs_pswn_yn // 2021-08-05 add 전역변수 패스워드 체크 set (Y일 시 java단에서 패스워드 체킹 skip)

      //if ( this.ds_search.procDate == '' ) {
      //  alert('debug alert // procDadate is null //' )
      //  return
      //}

      // var sSvcID = "selectTFIO42100List";
      // var sURL = "svc::selectTFIO42100List.do";
      // var sInDatasets = "tfio42100=ds_search";
      // var sOutDatasets = "ds_list=paymentList ds_detail=tfio42100";
      // var sArgument = "";
      // var sCallbackFunc = "fn_callBack";

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600165_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /***************************************************************************************
      출금가능금액과 수익자 계좌 조회후처리 
    *****************************************************************************************/
    fn_callBack(res) {
      const resData = Object.assign({}, res.data);
      if (TSCommUtil.gfn_trim(resData.errorCode) != "") {
        if ( TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(resData.errorMsg)) ) { // 에러메시지가 빈 값일 경우
          this.fn_AlertPopup(0, { content: resData.errorCode });
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg });
        }
        return false;
      }
      
      Object.assign(this.ds_detail, resData) 
      if ( TSCommUtil.gfn_length(resData.payment) > 0 ) {
        Object.assign(this.ds_list, resData.payment[0]) 
        Object.assign(this.ds_list00, this.ds_list) // 원본 복사
      }

      let oamtAmt00 = 0; //출금금액, 2018.09.11 add
      let rthgTmtlTcurAmt00 = 0; //현물출고액, 2018.09.11 add
      this.ds_list.oamtAmt00 = this.ds_list.oamtAmt
      oamtAmt00 = Number(oamtAmt00) + Number(this.ds_list.oamtAmt00)
      rthgTmtlTcurAmt00 = Number(rthgTmtlTcurAmt00) + Number(this.ds_list.rthgTmtlTcurAmt)
      this.edt_rthgPymYn = this.ds_list00.rthgPymYn // 실물여부

      //2018.09.11 add : 전액현물(수익=0) 아니면 -> (현물+고유계정대)
      if (this.edt_rthgPymYn == "Y" && Number(oamtAmt00) != Number(rthgTmtlTcurAmt00)) {
        //현물+고유계정대
        oamtAmt00 = Number(oamtAmt00) - Number(rthgTmtlTcurAmt00); //출금금액 - 현물출고액 => 고유계정대(지급금액)
        this.ds_list.oamtAmt00 = oamtAmt00
      }
      //end

      /**
       * 2023.05.22 추가 요청상항
       * 브릿지 금액과 이체금액 위치 변경 요청함 
       */
      let tmpFtrnAmt = this.ds_list.ftrnAmt
      this.ds_list.ftrnAmt = this.ds_list.brigIamtAmt
      this.ds_list.brigIamtAmt = tmpFtrnAmt
      
      if (this.edt_rthgPymYn == "Y") { // 기존 소스에서도 분기의 차이가 없음....
        // 거래금액 = 현금금액
        this.edt_cashAmt = "0"
        this.enabledList.edt_cashAmt = false
        this.enabledList.grd_list = true
        this.Static48 = "현금지급액"
        this.fn_trAmtSum();
      } else {
        //alert("현금지급액");
        this.edt_cashAmt = "0"
        this.enabledList.edt_cashAmt = false
        this.enabledList.grd_list = true
        this.Static48 = "현금지급액"
        this.fn_trAmtSum();
      }
    },

    /***************************************************************************************
      수익자 추가 팝업 호출 결과 
    *****************************************************************************************/
    fn_popupAfter01() {
      // 팝업에서 추가데이터가 존자하는경우
      if (!TSCommUtil.gfn_isNull(this.pv_popupResult.cnm)) {
        var nRow = this.ds_list.addRow();

        this.ds_list.setColumn(nRow, "cnm", this.pv_popupResult.cnm);
        this.ds_list.setColumn(
          nRow,
          "brigCpstAcno",
          this.pv_popupResult.brigCpstAcno
        );
        this.ds_list.setColumn(
          nRow,
          "brigBnkbSeq",
          this.pv_popupResult.brigBnkbSeq
        );
        this.ds_list.setColumn(
          nRow,
          "brigAccSeq",
          this.pv_popupResult.brigAccSeq
        );
        this.ds_list.setColumn(nRow, "bnfcCsId", this.pv_popupResult.bnfcCsId);
        this.ds_list.setColumn(nRow, "trBkcd", this.pv_popupResult.trBkcd);
        this.ds_list.setColumn(nRow, "bnkAcno", this.pv_popupResult.bnkAcno);
        this.ds_list.setColumn(nRow, "bnkAccNm", this.pv_popupResult.bnkAccNm);
        this.ds_list.setColumn(nRow, "trBkcdNm", this.pv_popupResult.trBkcdNm);
        this.ds_list.setColumn(nRow, "oamtAmt00", "추가");
        this.ds_list.setColumn(nRow, "oamtAmt", 0);
      }
    },

    /************************************************************************************************
      - 그리드의 onheadclick 이벤트에 this.gfn_gridSort함수를 선언하여 사용한다.
      - 컬럼을 지정하여 정렬처리할 경우, 아래와 같이 분기처리한다.
        예) if(e.col == 3 || e.col == 5 || e.col > 8)
      - 데이터가 숫자인 경우, 데이터셋의 해당컬럼 타입이 number계열이여야 정상적으로 sort된다.
      *************************************************************************************************/
    grd_list_onheadclick() {
      this.gfn_gridSort(obj, e);
    },

    /************************************************************************************************
     * 돋보기 버튼 클릭
     ************************************************************************************************/
    grd_list_oncellclick(type) {
      // 비활성화상태에서는 동작하지 않는다.
      // if (!obj.enable) {
      //   return true;
      // }

      if (type == 'select') {
        this.pPopup321Obj.csId = this.ds_list.bnfcCsId
        this.fn_OpenPopup(1) //this.$refs.popup321.fn_Open()
          
      } else if (type == 'delete') {
        // 은행이체 계좌번호 삭제키
        this.ds_list.trBkcdNm = ""
        this.ds_list.bnkAcno = ""
        this.ds_list.bnkAccNm = ""
        this.ds_list.ftrnAmt = 0
      } 
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 계좌조회 / 1: 승인자요청 
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      console.log(pData)

      switch (type) {
        case 0: // fn_popupAfter02 참조
          if (TSCommUtil.gfn_isNull(pData.bnkAcno)) 
            return false
          // 팝업에서 추가데이터가 존재하는 경우
          this.ds_list.trBkcd = pData.trBkcd
          this.ds_list.bnkAcno = pData.bnkAcno
          this.ds_list.bnkAccNm = pData.bnkAccNm
          this.ds_list.trBkcdNm = pData.trBkcdNm
          this.ds_list.stipSeq = pData.stipSeq
          break
        case 1:
          console.log('승인자요청 callback...')
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack_206
     * 설명       : 계좌조회(206p) 컴포넌트에서 callback
     ******************************************************************************/
    fn_Popup_CallBack_206 ( pData ) {
      console.log(pData)
      if (!TSCommUtil.gfn_isNull(pData.tacno)) {
        this.Edt_acno = pData.tacno + pData.bnkbSeq
        this.edt_accSeq = pData.accSeq
        this.fn_transaction(); // 조회 처리 
      }	
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack_106
     * 설명       : 승인자요청(106p) 컴포넌트에서 callback
     ******************************************************************************/
    fn_Popup_CallBack_106 ( pData ) { // pData == successYn
      if (!TSCommUtil.gfn_isNull(pData) && pData == "Y") {
        this.fn_confirm();
      } else {
        this.edt_transAprvNo = ''
        this.edt_chmnAprvNo = ''
      }
    },

    /***************************************************************************************
      거래금액 변경 시
    *****************************************************************************************/
    fn_trAmtSum() {
      let bankIamt = 0; // 은행이체 금액 합산
      let bridgeIamt = 0; // 브릿지계좌입금액
      let rthgTmtlTcurAmt = 0; //현물출고액, 2018.09.11 add
      
      bankIamt = Number(bankIamt) + Number(this.ds_list.ftrnAmt)
      bridgeIamt = Number(bridgeIamt) + Number(this.ds_list.brigIamtAmt)
      rthgTmtlTcurAmt = Number(rthgTmtlTcurAmt) + Number(this.ds_list.rthgTmtlTcurAmt) //현물출고액, 2018.09.11 add

      this.edt_rsrvAmt = bankIamt // 이체금액
      this.edt_brigIamtAmt = bridgeIamt // 브릿지계좌금액
      this.edt_rthgTmtlAmt = rthgTmtlTcurAmt //현물출고액, 2018.09.11 add
      //this.Div02.edt_trAmt.set_value(Number(bankIamt) + Number(bridgeIamt)); // 출금총액
      // 출금총액 =  은행이체금액+브릿지계좌입금액+현물출고액, 2018.09.11 add
      this.edt_trAmt = Number(bankIamt) + Number(bridgeIamt) + Number(rthgTmtlTcurAmt)
    },

    /***************************************************************************************
      거래금액 변경 시
    *****************************************************************************************/
    ds_list_oncolumnchanged() {
      this.fn_trAmtSum(); // 출금금액합산
      if (Number(this.ds_list.ftrnAmt) == 0) {
        this.ds_list.trBkcdNm = ''
        this.ds_list.bnkAcno = ''
        this.ds_list.bnkAccNm = ''
      }

      if (TSCommUtil.gfn_getNum(this.ds_list.brigIamtAmt) == 0) {
        this.ds_list.bridgeAcno = ''
      } else {
        let strBridgeAcno = this.ds_list.brigCpstAcno + "-" + this.ds_list.brigBnkbSeq + "-" + this.ds_list.brigAccSeq
        this.ds_list.bridgeAcno = strBridgeAcno
      }
    },

    /***************************************************************************************
      확인 처리 
    *****************************************************************************************/
    fn_confirm () {
      let isConfirmProc = true
      if ( this.lv_basInfo.brcd == "916") {
        this.fn_AlertPopup(0, { content: '신탁지원부는 처리 할 수 없습니다.' });
        return false;
      }

      // 입력값 확인
      if (TSCommUtil.gfn_isNull(this._getValue())) {
        // this.fn_addValidErr(this.$refs.Edt_acno.$el)
        // this.$refs.Edt_acno.focus()
        // TSCommUtil.gfn_validate(this, '계좌번호를 입력하십시오.')
        TSCommUtil.fn_validate( this, [{ content: '계좌번호를 입력하십시오.', ref:'Edt_acno' }] )
        return false;
      }

      if (this.gArs_pswn_yn == "N") {
        // 2021-08-05 add 전역변수 패스워드 체크
        if (TSCommUtil.gfn_isNull(this.edt_pwd)) {
          TSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력하십시오.', ref:'edt_pwd' }] )
          return false;
        }
      }

      if (TSCommUtil.gfn_isNull(this.ds_detail.rmkC)) {
        // 일반출금
        if (TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(this.ds_list.cnm))) {
          this.fn_AlertPopup(0, { content: '수익자가 없습니다.' });
          return false;
        }
        // 일반출금
        if (this.edt_rthgPymYn != "Y") {
          // 수익자개인별 출금가능 총합 비교
          
          let tfrnAmtSum = TSCommUtil.gfn_getNum(this.ds_list.ftrnAmt)
          let brigIamtAmtSum = TSCommUtil.gfn_getNum(this.ds_list.brigIamtAmt)
          let oamtAmtSum = TSCommUtil.gfn_getNum(this.ds_list.oamtAmt) 
          let tmtlAmt = TSCommUtil.gfn_getNum(this.ds_list.tmtlAmt)
          let totAmt = tfrnAmtSum + brigIamtAmtSum;

          if (oamtAmtSum != totAmt) {
            this.fn_AlertPopup(0, { content: ['출금가능금액과 출금총액이 상이합니다.', '확인하시기 바랍니다.'] });
            return false;
          }

          if (TSCommUtil.gfn_getNum(this.ds_list.ftrnAmt) > 0) {
            // 이체 금액이 존재하면
            // 이체계좌가 있어야한다.
            if (TSCommUtil.gfn_isNull(this.ds_list.bnkAcno)) {
              this.fn_AlertPopup(0, { content: ['이체계좌를 확인하시기 바랍니다.'] });
              return false;
            }
          }

          // 이체 계좌는 있는데 이체 금액이 없는경우
          if (
            !TSCommUtil.gfn_isNull(this.ds_list.bnkAcno) &&
            TSCommUtil.gfn_getNum(this.ds_list.ftrnAmt) == 0
          ) {
            this.fn_AlertPopup(0, { content: ["송금계좌를 선택한 경우 이체요청금액은 0이상 입력되어야합니다.', '은행이체를 원하지 않을경우 송금계좌를 삭제하여 주시기 바랍니다."] });
            return false;
          }
        }

        if ( Number(this.edt_trAmt) != Number(this.edt_payAbleAmt) ) {
          this.fn_AlertPopup(0, { content: ["출금가능금액과 출금총액이 상이합니다.", "확인하시기 바랍니다."]});
          return false;
        }

        if ( TSCommUtil.gfn_isNull(this.edt_chmnAprvNo) && TSCommUtil.gfn_isNull(this.edt_transAprvNo) ) {
          let comfirmCntt = "";
          if (this.edt_rthgPymYn != "Y") {
            if (Number(this.edt_rsrvAmt) > 0) {
              comfirmCntt =
                comfirmCntt +
                "은행이체 요청금액  " +
                this.$bizUtil.numberWithCommas(Number(this.edt_rsrvAmt) ) +
                "원";
            }
            if (Number(this.edt_brigIamtAmt) > 0) {
              comfirmCntt =
                comfirmCntt +
                "\n브릿지입금액         " +
                this.$bizUtil.numberWithCommas(Number(this.edt_brigIamtAmt) ) +
                "원 으로\n";
            } else {
              comfirmCntt = comfirmCntt + " 으로\n";
            }
          }
          comfirmCntt =
            comfirmCntt +
            "출금하실 금액이 총 " +
            this.$bizUtil.numberWithCommas(Number(this.edt_trAmt) ) +
            "원 입니다.\n";
          comfirmCntt = comfirmCntt + "처리하시겠습니까?";

          // if (!this.gfn_getMessage("confirm", comfirmCntt)) {
          //   return false;
          // }

          isConfirmProc = false // 팝업 콜백으로 처리
          this.fn_AlertPopup(0,{ 
            content : comfirmCntt
            , confirmFunc: this.fn_confirmProc
            , confirm: true
            // , single: false
          })
        }
      } else {
        // 보수출금
        isConfirmProc = false // 팝업 콜백으로 처리
        this.fn_AlertPopup(0, { 
          content : "보수출금 처리할 금액은 " +
              this.$bizUtil.numberWithCommas(Number(this.edt_bosuAmt) ) +
              "원 입니다."
          , confirmFunc: this.fn_confirmProc 
          , confirm: true
          , single: true
        })
      }

      if ( isConfirmProc == true )
        this.fn_confirmProc()
    },

    /***************************************************************************************
      팝업 확인 처리 
    *****************************************************************************************/
    fn_confirmCallBack ( res ) {
      const resData = Object.assign({}, res.data);
      if (TSCommUtil.gfn_trim(resData.errorCode) != "") {
        if ( TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(resData.errorMsg)) ) { // 에러메시지가 빈 값일 경우
          this.fn_AlertPopup(0, { content: resData.errorCode });
        } else {
          this.fn_AlertPopup(0, { content: resData.errorMsg });
        }
        return false;
      }
      
      Object.assign(this.ds_insert, resData)
      this.ds_tfgoal = resData.tfgoal
      this.ds_pbpr = resData.passbook

      console.log('fn_confirmCallBack 리스폰스')
      console.log(JSON.stringify(resData))

      // 승인번호 설정
      this.edt_chmnAprvNo = this.ds_insert.chmnAprvNo

      // 책임자 승인번호 있는경우 책임자 승인 팝업 호출
      if ( TSCommUtil.gfn_getNum(this.ds_insert.chmnAprvNo) > 0) {
        // 1. 승인화면 캡쳐(일자+지점+승인번호로 파일명을 생성한다.)
        var fileName =
          this.lv_basInfo.busyDate +
          this.lv_basInfo.brcd +
          this.ds_insert.chmnAprvNo +
          ".png";

        // 2. 승인요청 팝업 호출
        var oArg = {
          pProcDate: this.lv_basInfo.busyDate,
          pBrcd: this.lv_basInfo.brcd,
          pMngAprvSeq: this.ds_insert.chmnAprvNo,
          pFileName: fileName,
          pReqEmno: this.lv_basInfo.usid,
        }; //팝업화면으로 보낼 파라미터.

        console.log(oArg)
        this.$refs.popup106.fn_Open(oArg)
        return;
      }

      // 이체 처리가 없는 경우에
      for (var i; i < this.ds_list.length; i++) {
        if (TSCommUtil.gfn_getNum(this.ds_list.ftrnAmt) == 0) {
          // 이체 금액이 없는경우 정상처리로 표시
          this.ds_list.setColumn(i, "ftrnProsResult", "정상처리");
        }
      }
      if (!TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(this.ds_list.cnm)) ) {
        if (TSCommUtil.gfn_getNum(this.ds_list.ftrnAmt) == 0) {
          // 이체 금액이 없는경우 정상처리로 표시
          this.ds_list.ftrnProsResult = "정상처리"
        }
      }
      
      // 출금처리 완료 후 이체 내역 처리
      if (this.ds_tfgoal.length > 0) {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600162_S'
        this.eaiCommObj.params = {
          TFGOAL : this.ds_tfgoal, 
        }
        Object.assign(this.eaiCommObj.params, this.ds_tfgoal)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_ftrnCallBack) // 전자서식 출력 포함

      } else {
        let lv_Vm = this
        let lastPopupObj = {
          content: '',
          confirmFunc: lv_Vm.fn_reportTFIO42100,
        }
        this.nextFunc = function (){
          let args = {
            tacno: lv_Vm._getTacnoValue(),
            bnkbSeq: lv_Vm._getBnkbSeqValue(),
            accSeq: lv_Vm._getAccSeqValue(),
            baseDate: lv_Vm.cal_bizDate,
          }
          lv_Vm.$router.push({ name: 'MSPTS203M' , params: args })
        }

        let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
        if ( isProcess ) {
          lastPopupObj.content = ['정상 처리 되었습니다.', '신탁이익계산서 화면으로 이동하시겠습니까?'] 
        } else {
          lastPopupObj.content = ['신탁 출금이 완료되었습니다.']    
        }
        this.fn_checkProcess(lastPopupObj)
      }
    },

    /***************************************************************************************
      확인 팝업 확인시 실행할 콜백함수
    *****************************************************************************************/
    fn_confirmProc () {
      // 입력데이터 초기화  
      Object.assign(this.ds_search, defaultDsSearch)
      Object.assign(this.ds_insert, defaultDsInsert)
      this.ds_pbpr = [], // Object.assign(this.ds_pbpr, defaultDsPbpr)
      this.ds_tfgoal = [], //Object.assign(this.ds_tfgoal, defaultDsTfgoal)
      
      //조회용 dataset에 조회할 항목을 넣어준다.
      this.ds_search.tacno = this._getTacnoValue()
      this.ds_search.bnkbSeq = this._getBnkbSeqValue()
      this.ds_search.accSeq = this._getAccSeqValue()
      this.ds_search.procDate = this.cal_bizDate
      this.ds_search.pwd = this.edt_pwd
      this.ds_search.msUseYn = this._getMsUseYn()        // 통장리드여부
      this.ds_search.msData = this._getMsData()
      this.ds_search.offrSeq = this.ds_detail.offrSeq    // 계약승인번호
      this.ds_search.chmnAprvNo = this.edt_chmnAprvNo    // 책임자승인번호
      this.ds_search.transAprvNo = this.edt_transAprvNo  // 이체책임자승인번호
      this.ds_search.trAmt = this.edt_trAmt              // 거래금액
      this.ds_search.payAbleAmt = this.edt_payAbleAmt    // 출금가능금액
      this.ds_search.rmkC =  this.ds_detail.rmkC         // 적요코드 보수출금구분용
      this.ds_search.cashAmt =  this.edt_cashAmt
      this.ds_search.pwdYn =  this.gArs_pswn_yn          // 2021-08-05 add 전역변수 패스워드 체크 set (Y일 시 java단에서 패스워드 체킹 skip)
      this.ds_search.procBrcd = this.lv_basInfo.brcd     // 처리점

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600160_S'
      this.eaiCommObj.params = this.ds_search
      this.eaiCommObj.params = {
        Payment : [this.ds_list], 
      }
      Object.assign(this.eaiCommObj.params, this.ds_search)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_confirmCallBack)
    },

    /***************************************************************************************
      신탁출금  보고서 출력 2021-08-17 add
    *****************************************************************************************/
    async fn_reportTFIO42100 () {
      //dataset 동적생성
      let ds_param = {}
      ds_param.name = "payment";

      //컬럼, 타입추가
      ds_param.tacno = ''
      ds_param.bnkbSeq = ''
      ds_param.accSeq = ''
      ds_param.cnm = ''
      ds_param.procDate = ''
      ds_param.bnkAccNm = ''
      ds_param.trBkcdNm = ''
      ds_param.bnkAcno = ''
      ds_param.totalPay = ''
      ds_param.procEnnm = ''
      ds_param.rcno = ''
      ds_param.fromBnk = ''
      ds_param.teleVssTc = '' //2021-11-23 add

      //row 추가
      ds_param.tacno = this._getTacnoValue()          //종합계좌번호
      ds_param.bnkbSeq = this._getBnkbSeqValue()      //통장번호
      ds_param.accSeq = this._getAccSeqValue()        //계약일련번호
      ds_param.procDate = this.cal_bizDate            //처리일
      ds_param.fromBnk = "Y"                          //은행이체, 계좌대체 구분 값 (Y:은행이체, N:계좌대체)
      ds_param.teleVssTc = this.ds_detail.teleVssTc   // 유선, 창구 구분 2021-11-23 add (1:유선 2:내방)

      // 송금계좌로 이체금액이 있을 때 (이 때 브릿지 계좌 출금이 일부 있더라도 송금은행 출금신청서만 출력)
      if (Number(this.ds_list.ftrnAmt) > 0) {
        ds_param.totalPay = this.ds_list.ftrnAmt      // ds_list 리스트 index 확인 필요   //신청금액 (그리드 : 이체요청금액)    
        ds_param.bnkAccNm = this.ds_list.trBkcdNm     //입금 고객명
        ds_param.trBkcdNm = this.ds_list.bnkAccNm     // 입금 은행명
        ds_param.bnkAcno = this.ds_list.bnkAcno       //입금 계좌번호
      } // 브릿지 계좌로 전액 출금할 때
      else {
        ds_param.totalPay = this.ds_list.brigIamtAmt  // 신청금액 (그리드 : 브릿지입금액)
        ds_param.bnkAccNm = this._getCnmValue()       // 입금 고객명
        ds_param.trBkcdNm = '브릿지'                  // 입금 은행명
        ds_param.bnkAcno = this.ds_list.bridgeAcno    // 입금 계좌번호
      }

      // var reportMrdNm = "/cronix/fiduciary/ioamt/TFIO42100.mrd"; // 보고서 파일 경로 (출금 신청서)
      // var reportUrl = "reportTFIO42100.do"; // 보고서 호출 서비스url
      // var reportParam = ds_param;
      
      let formList = []

      // 공통필수: 전자서명 동의서 
      let TS000001 = {formId: 'TS000001'}
      TS000001.params = {
        reportParam: JSON.stringify( {
            tacno   : ds_param.tacno,
            bnkbSeq : ds_param.bnkbSeq,
            accSeq  : ds_param.accSeq,
            ctno    : Number(ds_param.accSeq),
            csId    : this.ds_detail.csId,
        } ),
        reportUrl: 'reportTFAC20000',
      }   
      formList.push(TS000001)

      // 해지 신청서
      let TS000056 = {formId: 'TS000056'} // TFIO42100.mrd | TS000056_해지신청서
      TS000056.params = {
        reportParam: JSON.stringify( {
            tacno   : ds_param.tacno,
            bnkbSeq : ds_param.bnkbSeq,
            accSeq  : ds_param.accSeq,
            offrSeq : this.ds_detail.offrSeq,
        } ),
        reportUrl: 'reportTFAC20100List',
      }
      formList.push(TS000056)


      let TS000057 = {formId: 'TS000057'} // TFIO42100.mrd | TS000057_출금신청서
      TS000057.params = {
        reportParam: JSON.stringify(ds_param),
        reportUrl: 'reportTFIO42100',
      }
      formList.push(TS000057)
      // TSInfoUtil.commReportInfo(this, formList) 
      if ( this.lv_isProcess ) {  
        TSInfoUtil.commReportInfo (this, formList, this.nextFunc, false, 'MSPTS208M');
      } else {  // 개별일 경우 해당페이지는 마지막이 됨
        this.getStore('tsStore').dispatch('IS_LAST_START')
        TSInfoUtil.commReportInfo(this, formList) 
      }
      console.log(TS000057)
    },

    /***************************************************************************************
      이체처리 후 처리 
    *****************************************************************************************/
    fn_ftrnCallBack ( res ) {
      const resData = Object.assign({}, res.data);
      let lv_Vm = this
      
      if (TSCommUtil.gfn_trim(resData.errorCode) != "") {
        // this.fn_AlertPopup(0, { content: resData.errorMsg });
        this.fn_AlertPopup(0, { 
            confirm: true,
            single: true,
            confirmFunc: function(){
              this.$router.push({ 
                name: 'MSPTS205M',
                params:{
                  tacno:   lv_Vm.ds_search.tacno,   // 종합계좌번호
                  bnkbSeq: lv_Vm.ds_search.bnkbSeq, // 통장일련번호
                  accSeq:  lv_Vm.ds_search.accSeq,  // 계좌일련번호
                },
             })
            },
            content: ['이체처리가 정상적으로 수행되지 않았습니다.', '이체처리명세로 이동하여 재이체처리 하시기 바랍니다.'] 
          }
        );
        return false;
      }
      
      // 데이터 셋팅
      this.ds_tfgoal = resData.tfgoal

      for (var i = 0; i < this.ds_list.length; i++) {
        for (var j = 0; j < this.ds_tfgoal.length; j++) {
          if (
            this.ds_list[i].brigCpstAcno == this.ds_tfgoal[j].tacno 
            && this.ds_list[i].brigBnkbSeq == this.ds_tfgoal[j].bnkbSeq 
            && this.ds_list[i].brigAccSeq == this.ds_tfgoal[j].accSeq
            && this.ds_list[i].trBkcd == this.ds_tfgoal[j].bkcd 
            && this.ds_list[i].bnkAcno == this.ds_tfgoal[j].bnkAcno 
            && this.ds_list[i].ftrnAmt == this.ds_tfgoal[j].bktrTrAmt
          ) {
            this.ds_list[i].ftrnProsResult = this.ds_tfgoal[j].rstMsg
            this.ds_list[i].gramProcStat = this.ds_tfgoal[j].gramProcStat
            if (this.ds_tfgoal[j].gramProcStat != "Y") {
              break;
            }
          }
        }
      }

      // 4000 은행이체처리화면으로 이동
      let lastPopupObj = {}

      lastPopupObj.confirmFunc = lv_Vm.fn_reportTFIO42100
      this.nextFunc = function (){
        if ( lv_Vm.lv_isProcess ) {
          lv_Vm.$router.push({
            name: 'MSPTS205M',
            params:{
              tacno:   lv_Vm.ds_search.tacno,   // 종합계좌번호
              bnkbSeq: lv_Vm.ds_search.bnkbSeq, // 통장일련번호
              accSeq:  lv_Vm.ds_search.accSeq,  // 계좌일련번호
            },
          })
        } else {
          // 프로세스 아닌경우 초기화
          lv_Vm.fn_init();
        }
      }

      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      if ( isProcess ) {
        lastPopupObj.content = ['해지요청건의 출금처리가 완료되었습니다.', '처리결과 확인을 위해 이체처리명세로 이동합니다.' ]
      } else {
        lastPopupObj.content = ['신탁 출금이 완료되었습니다.']    
      }
      this.fn_checkProcess(lastPopupObj)
    
    },

    /***************************************************************************************
          이체내역 통장 인쇄 확인 후 처리 선택 
      *****************************************************************************************/
    popupTFIO00_onclick() {
      // 선택한행 삭제 처리 -- 데이터가 1건이면 삭제 불가
      if (this.ds_list.getRowCount() == 1) {
        this.gfn_getMessage("alert", "수익자를 삭제 할 수 없습니다.");
        return false;
      }

      if (
        TSCommUtil.gfn_getNum(
          this.ds_list.getColumn(this.ds_list.rowposition, "oamtAmt")
        ) > 0
      ) {
        this.gfn_getMessage("alert", "수익자를 삭제 할 수 없습니다.");
        return false;
      }

      for (i = 0; i < this.ds_list.getRowCount(); i++) {
        if (
          i != this.ds_list.rowposition &&
          this.ds_list.getColumn(this.ds_list.rowposition, "bnfcCsId")
        ) {
          this.ds_list.deleteRow(this.ds_list.rowposition);
          break;
        }
      }
    },

    /***************************************************************************************
          비밀번호 항목 클릭 
      *****************************************************************************************/
    Div01_edt_pwd_onsetfocus() {
      // 계좌번호 가 없는경우 계좌번호로 포커스 이동
      if (TSCommUtil.gfn_isNull(this.Div01.div_acno._getTacnoValue())) {
        this.Div01.div_acno.setFocus();
      } else {
        this.Div01.edt_pwd.set_value("");
        this.Div01.div_acno.div_device.fn_G_pinpadInput(this.Div01.edt_pwd);
        this.Div01.div_acno.btn_popupTPUP95350.setFocus();
      }
    },

    PopupDiv00_Button74_onclick() {
      this.Div01.edt_chmnAprvNo.set_value("");
    },

    /***************************************************************************************
        code        : 호출할 공통코드
        dsName      : 호출된 공통코드를 담을 Dataset
        selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        
        selecttype은 추후 변경될 수 있음.
        this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
    fn_comCode() {
      this.eaiCommObj.lv_vm = this;
      //this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        TUKEMK: [
          { cId: "0080" }, // ds_cmb0080 대표번호
        ],
      };
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack);
    },

    fn_comCodeCallBack(res) {
      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index];
        switch (item.cid) {
          case "0080": // 대표번호
            this.ds_cmb0080.push({ value: item.c, text: item.cnm });
            break;
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     
     * 버튼 구성 방법 TYPE == 0
     * 1. 확인(클릭시 콜백 없음) : pPopupObj.confirm == true
     * 2. 확인(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == true
     * 3. 확인,취소(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == false
       
     * 버튼 클릭 콜백 함수
     * pPopupObj.confirmFunc : function  // 확인 버튼 클릭 콜백함수
     * pPopupObj.closeFunc : function    // 취소 버튼 클릭 콜백함수
    ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0 : 
          TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
          this.$refs.alertPopup.fn_Open()
          break
        case 7: // 마지막 얼럿창
          TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj) 
          this.$refs.alertPopup.fn_Open()
          break
        case 9 : // 마지막 얼럿창
          TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
          this.$refs.alertPopup.fn_Open()            
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_validErrRemoveInput
     * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
     ******************************************************************************/
    fn_validErrRemoveInput(event, el) {
      const value = event.currentTarget.value;
      const _el = this.$refs[el].$el;
      if (!TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(value)))
        _el.classList.remove("error");
    },

    /************************************************************************************************
     * 계좌번호 입력 시
     ************************************************************************************************/
    Edt_acno_onkeyup(event) {
      console.log("Edt_acno_onkeyup.....");
      // var acnoValue = this.Edt_acno.value;
      let acnoValue = this.Edt_acno
      
      // 계좌번호 10자리 입력시 자동 조회 
      if ( TSCommUtil.gfn_length(acnoValue) == 10 ) {
        if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
          return 
        }
        this.fn_transaction();
        // this.fn_validErrRemoveInput(event, el); // error class 삭제 체크
      } 
    },

    /************************************************************************************************
     * 종합계좌컨트롤 조회 후 처리 
     ************************************************************************************************/
    acctCallBackFuncTacno ()
    {
        this.edt_pwd = ''
        this.gArs_pswn_yn = "N";							// 2021-08-05 add 계좌조회 후 ARS컴포넌트 초기화
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 계좌조회(popup206), 1: 송금계좌등록내역조회(popup321), 2: 책임자 승인(popup106)
     ******************************************************************************/
    fn_OpenPopup (type, flag) {
      switch (type) {
        case 0:
          this.$refs.popup206.fn_Open()
          break
        case 1:
          this.$refs.popup321.fn_Open()
          break          
        case 2:
          this.$refs.popup106.fn_Open()
          break          
      }
    },

    /************************************************************************************************
     * 패스워드 입력 시 
     ************************************************************************************************/
    pwd_onkeyup(event, el) {
      console.log('pwd_onkeyup.....')
      // var acnoValue = this.Edt_acno.value;
      let acnoValue = event.target.value
      const _el = this.$refs[el].$el
      
      if ( acnoValue.trim().length == 4 ) {
        this.fn_validErrRemoveInput(event, 'edt_pwd') // error class 삭제 체크
        this.fn_searchList()
      }
      
    },

    /******************************************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess (pObj) {
      let lv_Vm = this

      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      let isLast    = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
      let t_type    = 0

      if ( isProcess ) { 
        if ( !isLast ) {
          t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
        } else {
          t_type = 9 // 프로세스 진행 중 마지막 화면일 경우
        }
      } else { // 프로세스 진행 중이 아닐 경우
        t_type = 9 
      }
      
      let t_popupObj = {
        confirm      : true,
        confirmFunc  : pObj.confirmFunc,
        content      : pObj.content,
        contentTitle : '',
        confirmData: {
            tacno:   lv_Vm.ds_search.tacno,      // 종합계좌번호
            bnkbSeq: lv_Vm.ds_search.bnkbSeq,    // 통장일련번호
            accSeq:  lv_Vm.ds_search.accSeq,     // 계좌일련번호
            csId: '',                            // 정보동의서 수령방법 (메일, 전화번호 정보항목) 설정관련 추가
          },
      }
      
      // return t_popupObj
      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },

    /******************************************************************************
    * Function명 : fn_rmAllErrCss
    * 설명       : 해당 컴포넌트 error css 삭제
    ******************************************************************************/
    fn_rmAllErrCss () {
      for ( let idx in this.$refs ) {
        if ( this.$refs[idx] != undefined ) {
          this.$refs[idx].$el.classList.remove("error")
        }
      }
    },

    // 이익계산서 이동 테스트
    fn_test () {
      let lv_Vm = this
        let lastPopupObj = {
          content: '',
          confirmFunc: lv_Vm.fn_reportTFIO42100,
        }
        this.nextFunc = function (){
          let args = {
            tacno: lv_Vm._getTacnoValue(),
            bnkbSeq: lv_Vm._getBnkbSeqValue(),
            accSeq: lv_Vm._getAccSeqValue(),
            baseDate: lv_Vm.cal_bizDate,
          }
          lv_Vm.$router.push({ name: 'MSPTS203M' , params: args })
        }

        let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
        if ( isProcess ) {
          lastPopupObj.content = ['정상 처리 되었습니다.', '신탁이익계산서 화면으로 이동하시겠습니까?'] 
        } else {
          lastPopupObj.content = ['신탁 출금이 완료되었습니다.']    
        }
        this.fn_checkProcess(lastPopupObj)
    }, 

  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    'ds_detail' : {
      handler: function(val, oldVal) { 
        // 토탈값 초기화
        this.edt_aprvNo = TSCommUtil.gfn_getNum(val.aprvNo) 
        this.edt_payAbleAmt = TSCommUtil.gfn_getNum(val.payAbleAmt)
        this.edt_offrSeq = TSCommUtil.gfn_getNum(val.offrSeq)
        this.edt_bosuAmt = TSCommUtil.gfn_getNum(val.basFeeWcurAmt)
        this.edt_rmkC = TSCommUtil.gfn_getNum(val.rmkC)
        /**
         * 해당 라인 대응 로직 
         * <BindItem id="item4" compid="Div01.edt_transAprvNo" propid="value" datasetid="ds_insert" columnid="chmnAprvNo"/>
         */
      },
      deep: true,
    },
    ds_insert : {
      handler: function(val, oldVal) { 
        // 토탈값 초기화
        this.edt_transAprvNo = val.chmnAprvNo
      },
      deep: true,
    },
    'ds_list.ftrnAmt' : {
      handler: function(val, oldVal) { 
        // this.fn_trAmtSum(); // 출금금액합산
        // if (Number(val) == 0) {
        //   this.ds_list.trBkcdNm = ''
        //   this.ds_list.bnkAcno = ''
        //   this.ds_list.bnkAccNm = ''
        // }
        // let strBridgeAcno =
        //     this.ds_list.brigCpstAcno +
        //     "-" +
        //     this.ds_list.brigBnkbSeq +
        //     "-" +
        //     this.ds_list.brigAccSeq
        // this.ds_list.bridgeAcno = strBridgeAcno
        this.ds_list_oncolumnchanged()
      },
      deep: true,
    },
    'ds_list.brigIamtAmt' : {
      handler: function(val, oldVal) { 
        this.ds_list_oncolumnchanged()
      },
      deep: true,
    },

    /**********************************************************************************
     * 프로세스 체크
     **********************************************************************************/
    'isProcessCheck': {
      handler(after, before) {
        console.log('isProcessCheck > ', after)
        this.fn_init()
      },
      deep: true
    },
    
  },
};
</script>

<style lang="scss"></style>

<style scoped></style>
