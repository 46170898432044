/*
 ===============================================================================
 == 계좌조회 공통함수 정리
 파 일 명 : TPUP95090.xfdl (계좌번호콤보화면)
 =============================================================================== 
 ● gfn_InitMenu        : 메뉴 초기화 
 ***********************************************************************************
 *       수정일         이름         사유
 *********************************************************************************** 
 *    2023.05.18.      지재호        계좌조회 관련 util 
 */

 /***********************************************************************************
 * DataSet 영역	                                                                    *
 ***********************************************************************************/
const defaultDatasetTemp = {  
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  gdC: '',
  gdNm: '',
  trstTypC: '',
  cnm: '',
  rcno: '',
  rcnoKindTc: '',
  accStatC: '',
  bnkbPswd: '',
}

const defaultDsSearch = { 
  tacno: '',
  bnkbSeq: '',
  accStatC: '',
  brigAccYn: '',
}

const defaultDsList = { 
  tacno: '',
  bnkbSeq: '',
  accSeq: '',
  accStatC: '',
  gdNm: '',
  cnm: '',
  brigAccYn: '',
  curC: '',
  contDate: '',
  mtrtDate: '',
  trstTypC: '',
  gdC: '',
  trstAmt: '',
  gdTypDtlC: '',
  mngBrcd: '',
  csId: '',
  admkNm: '',
  bnkb: '',
  acc: '',
  fundC: '',
  rcno: '',
  rcnoKindTc: '',
  soptTc: '',
}

const defaultDsMsData = { 
  msData: '',
  pbprNo: '',
  msUseYn: '',
}

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

export const TSSearchMixin = {
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
  // this.ds_search_mix = Object.assign({}, this.propsObj) 
  },
  mounted() {
    this._fn_afterFormOnload()
    
    // this.mixTest()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      mixData: 'mix_data 입니다',
      eaiTest: '',
      msData: {},

      pbprUseYn: '',
      passYn: '',
      popCls: '',

      edt_cnm: '',  // 이름
      edt_curC: '' ,
      cal_contDate: '',
      cal_mtrtDate: '',
      edt_brigAccYn: '',
      edt_trstTypC: '',
      edt_gdC: '',
      edt_trstAmt: '',
      edt_gdTypDtlC: '',
      edt_mngBrcd: '',
      edt_csId: '',
      edt_cnm: '',
      edt_fundC: '',
      edt_rcno: '',
      edt_rcnoKindTc: '',
      edt_soptTc: '',
      edt_gdNm: '',

      // 추가
      Edt_accStatC_inData: '',
      edt_accSeq: '',

      /***************************************************************************************
      * 메인 페이지에 따른 사용 변수
      *****************************************************************************************/
      cal_from: '',     //  거래내역 조회       ds_search_mix.m_id == MSPTS110M
      cal_to: '',       //  거래내역 조회       ds_search_mix.m_id == MSPTS110M
      cal_baseDate: '', //  신탁운용자산현황    ds_search_mix.m_id == MSPTS102M

      /*********** 
      * 공통  
      ************/
      enabledList: { Cbo_accSeq:'' },
      visibleList: { Cbo_accSeq:'' },
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
      
      // 팝업 데이터 객체
      pPopup206Obj: {},   // 계좌번호 조회
      pAlertPopupObj: {}, // 공통 객체

      DatasetTemp: Object.assign({}, defaultDatasetTemp),
      ds_msData: Object.assign({}, defaultDsMsData),
      ds_search_mix: Object.assign({}, defaultDsSearch),
      ds_list_mix: [],
    };
  },
  methods: {
    mixTest() {
      console.log('mix에서 mixData 호출 시작')
      console.log(this.mixData)
      console.log('mix에서 mixData 호출 끝')
      this.eaiTest = TSCommUtil.gfn_eaiCommObj()
      Object.assign(this.msData, defaultDsMsData)
      console.log(this.msData)
    },

    // 부모창에서 호출
    sendObj (context , pObj) {
      // this.popupObj = pObj
      // this.ds_search_mix = Object.assign({}, context.$data.ds_search_mix) 
      // Object.assign(this.ds_search_mix, context.$data.m_ds_search_mix) 
      this.ds_search_mix.time = pObj.time
    },

    fn_test () {
      // test
      // this.ds_search_mix.cal_to = Math.floor(Math.random() * 1000)
      
    },
    
    /***************************************************************************************
        실질적인 화면 초기화 
    *****************************************************************************************/
    _fn_afterFormOnload ()
    { 
      this.pbprUseYn = "N";
      this.passYn = "N";
    },

    /************************************************************************************************
     * 데이터 초기화 
     ************************************************************************************************/
    // _fn_dataInit ()
    // { 
    //   this.Edt_acno = ''
    //   this.edt_cnm =''
    //   this.ds_list = []
    //   Object.assign(this.ds_search_mix, defaultDsSearch)
    //   Object.assign(this.DatasetTemp, defaultDatasetTemp)
    //   Object.assign(this.ds_msData, defaultDsMsData)
    // },

    /************************************************************************************************
     * 화면 조회 값 설정 
     * @see strAcno 파라미터 사용 하지 않음
     ************************************************************************************************/
    _setValue ( strAcno, strAccSeq, strAcctStatC, strPopCls, strPassYn )
    {
    
      this.popCls = strPopCls;
      
      // 팝업구분에 따른 컨트롤 위치 변경 
      if ( strPopCls == "1" || strPopCls == "3" || strPopCls == "4") 
      {
        this.enabledList.Cbo_accSeq = false
        this.visibleList.Cbo_accSeq = false
      }
      
      if ( strPopCls == "0" ||  strPopCls == "2" ) 
      {
        this.enabledList.Cbo_accSeq = true
        this.visibleList.Cbo_accSeq = true
      }
      
      if ( !TSCommUtil.gfn_isNull(strPassYn)) {
        this.passYn = strPassYn; // 비밀번호 사용여부 
      }
      
      // 계좌 상태 코드 지정 
      let varAcctStatC = new String(TSCommUtil.gfn_nvl ( strAcctStatC , "" ) );	
      this.Edt_accStatC_inData = varAcctStatC  // 값이 없는 경우 계좌 전체 조회 
      this.edt_accSeq = strAccSeq // 계좌일련번호 
      this.Edt_acno = strAcno 
      var varAcno = new String(strAcno);

      // 계좌번호를 입력받은 경우 바로 조회한다. 
      if ( TSCommUtil.gfn_length(this.Edt_acno) == 10 ) { 
        if(strPopCls == "4"){
        
        this.fn_transactionB(this.Edt_acno)

        }else{
        this.fn_transaction(this.Edt_acno)
        }
      }
      
    },

    /************************************************************************************************
     * 화면조회구분 세팅 
     * 호출하는 곳 없음
     ************************************************************************************************/
    fn_popCls ( strPopCls )
    {
      // this.popCls = strPopCls;
      
      // // 팝업구분에 따른 컨트롤 위치 변경 
      // if ( strPopCls == "1" || strPopCls == "3" || strPopCls == "4" ) 
      // {
      //   this.Cbo_accSeq.set_visible(false);
      //   this.Cbo_accSeq.set_enable(false);
      //   this.btn_popupTPUP95350.set_left(91);
      //   this.edt_cnm.set_left(115); 
      //   this.edt_cnm.set_width(200); 
      //   this.Div00.set_left(315);
      // }
      
      // if ( strPopCls == "0" ||  strPopCls == "2" ) 
      // {
      //   this.Cbo_accSeq.set_visible(true);
      //   this.Cbo_accSeq.set_enable(true);
      //   this.btn_popupTPUP95350.set_left(283); 
      //   this.edt_cnm.set_left(307); 
      //   this.edt_cnm.set_width(135); 
      //   this.Div00.set_left(442);
      // }
      
      // // 계좌번호를 입력받은 경우 바로 조회한다. 
      // if ( this.gfn_length( this.Edt_acno) == 10 ) { 
      //   if(strPopCls == "4"){
        
      //   this.fn_transactionB(this.Edt_acno);

      //   }else{
      //   this.fn_transaction(this.Edt_acno);
      //   }
      // }
    },

    /************************************************************************************************
     * 계좌번호 값 리턴 
     ************************************************************************************************/
    _getValue ()
    {		
       // 데이터가 있는경우 
      if ( this.ds_list_mix.length > 0 ) {
        var oParamData = this.Edt_acno + this.Cbo_accSeq
        return oParamData;
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 종합계좌번호 값 리턴 
     ************************************************************************************************/
    _getTacnoValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        
        var oParamData = this.Edt_acno;
        
        return oParamData.toString().substr(0,7);	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * ISA 계좌여부 값 리턴 
     ************************************************************************************************/
    _getIsaAcnoYn ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        // 특정금전신탁이고, 상세유형이 E@ISA 인것 
        if ( this.edt_trstTypC == "001" && this.edt_gdTypDtlC == "E" ) {
          var oParamData = "Y";	
        }
        else {
          var oParamData = "N";	
        }	
        return oParamData;
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 통장일련번호 값 리턴 
     ************************************************************************************************/
    _getBnkbSeqValue ()
    {	
     // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.Edt_acno
        return oParamData.toString().substr(7,3);	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 계좌일련번호 값 리턴 
     ************************************************************************************************/
    _getAccSeqValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.Cbo_accSeq
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 상품명 값 리턴 
     ************************************************************************************************/
    _getGdNmValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_gdNm
        return oParamData.toString().substr(4,100);	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 고객명 값 리턴 
     ************************************************************************************************/
    _getCnmValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_cnm;
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 브릿지계좌여부 값 리턴 
     ************************************************************************************************/
    _getBrigAccYnValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_brigAccYn;
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return '' 
      }	
    },

    /************************************************************************************************
     * 브릿지계좌번호 리턴 
     ************************************************************************************************/
    _getBridgeAcno () 
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        for ( var i = 0 ; i < this.ds_list_mix.length ; i ++ ) {
          if ( this.ds_list_mix[i].brigAccYn == "Y" ) {
            var oParamData = this.ds_list_mix[i].tacno 
            oParamData += this.ds_list_mix[i].bnkbSeq 
            oParamData += this.ds_list_mix[i].accSeq 
            return oParamData;	
          }
        }		
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
     * 통화코드 값 리턴 
     ************************************************************************************************/
    _getCurCValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_curC
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 계약일자 값 리턴 
    ************************************************************************************************/
    _getContDateValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.cal_contDate
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 만기일자 값 리턴 
    ************************************************************************************************/
    _getMtrtDateValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.cal_mtrtDate
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 상품유형 값 리턴 
    ************************************************************************************************/
    _getTrstTypCValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_trstTypC
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 상품구분 값 리턴 
    ************************************************************************************************/
    _getGdCValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_gdC
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 신탁금액 값 리턴 
    ************************************************************************************************/
    _getTrstAmtValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_trstAmt
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 관리지점코드 값 리턴 
    ************************************************************************************************/
    _getMngBrcdValue ()
    {		
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_mngBrcd
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return '' 
      }	
    },

    /************************************************************************************************
    * 고객ID 값 리턴 
    ************************************************************************************************/
    _getCsIdValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_csId
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 계좌여부 리턴
    ************************************************************************************************/
    _getBnkbAccYnValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_bnkbAccYn
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 고객ID 값 리턴 
    ************************************************************************************************/
    _getFundCValue ()
    {	
      // 데이터가 있는경우 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_fundC
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 실명번호 값 리턴 
    ************************************************************************************************/
    _getRcno ()
    {	
      // 데이터가 있는경우 
      if(!this.gfn_isNull(this.Edt_acno)  && !this.gfn_isNull(this.edt_rcno)){	
        var oParamData = this.edt_rcno
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },


    /************************************************************************************************
    * 실명구분 값 리턴 
    ************************************************************************************************/
    _getRcnoKindTc ()
    {	
      // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_rcnoKindTc
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 자타익구분 값 리턴 
    ************************************************************************************************/
    _getSoptTc ()
    {	
      // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        var oParamData = this.edt_soptTc
        return oParamData;	
      // 데이터가 없는 경우
      }else{
        return ''
      }	
    },

    /************************************************************************************************
    * 계좌 정보 조회 TR 전송 
    ************************************************************************************************/
    fn_transaction ()
    {
      this.ds_list_mix = [] // 데이터 셋 초기화
      Object.assign(this.ds_search_mix, defaultDsSearch) 

      // 계좌번호 조회 
      this.ds_search_mix.tacno =  this.Edt_acno.toString().substr(0,7) 
      this.ds_search_mix.bnkbSeq =  this.Edt_acno.toString().substr(7,3) 
      this.ds_search_mix.accStatC =  this.Edt_accStatC_inData 
      this.ds_search_mix.brigAccYn =  this.edt_brigAccYn01 
      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search_mix)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this._fn_callBack)

      // var sSvcID        = "selectTPUP95090List";
      // var sURL          = "svc::selectTPUP95090List.do";
      // var sInDatasets   = "tpup95090=ds_search_mix";
      // var sOutDatasets  = "ds_list_mix=tpup95090List";
      // var sArgument     = "";
      // var sCallbackFunc = "_fn_callBack";
      
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
    },

    /************************************************************************************************
    * 통장 정보 조회 TR 전송 
    ************************************************************************************************/
    fn_transactionB ()
    {
      this.ds_list_mix = [] // 데이터 셋 초기화
      Object.assign(this.ds_search_mix, defaultDsSearch) 

      // 통장번호 조회 
      this.ds_search_mix.tacno =  this.Edt_acno.toString().substr(0,7)
      this.ds_search_mix.bnkbSeq =  this.Edt_acno.toString().substr(7,3)
      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600246_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search_mix)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this._fn_callBack)

      
      // var sSvcID        = "selectTPUP95090ListB";
      // var sURL          = "svc::selectTPUP95090ListB.do";
      // var sInDatasets   = "tpup95090=ds_search_mix";
      // var sOutDatasets  = "ds_list_mix=tpup95090List";
      // var sArgument     = "";
      // var sCallbackFunc = "_fn_callBack";
      
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
    },

    /************************************************************************************************
    * 계좌조회 정상응답 후 처리 
    ************************************************************************************************/
    _fn_callBack ( res )
    {
      // 응답코드가 정상이 아닌경우 
      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }

      if ( TSCommUtil.gfn_length(resData.tpup95090) > 0 ) {
        // Object.assign ( this.ds_list_mix, resData.tpup95090) 
        this.ds_list_mix = resData.tpup95090
        let rtn = []
        this.ds_list_mix.forEach(function(item,idx){
          rtn.push({value: item.accSeq , text: item.gdNm })
        })
        this.list_Cbo_accSeq = rtn
      }
      else {
        // this.fn_AlertPopup(0,{content : '결과가 존재하지 않습니다.'}) 
        // this.$emit('ts-alert-popup', 0, {content : '결과가 존재하지 않습니다.'})
        // this.$emit('ts-fn_init') // 메인화면 초기화
        this.fn_AlertPopup(0,{content : '계좌 결과가 존재하지 않습니다.'}) 
        this.fn_init()
        return false
      }
    
      // 초기값 설정 
      this.edt_curC = this.ds_list_mix[0].curC
      this.cal_contDate = this.ds_list_mix[0].contDate
      this.cal_mtrtDate = this.ds_list_mix[0].mtrtDate
      this.edt_brigAccYn = this.ds_list_mix[0].brigAccYn
      this.edt_trstTypC = this.ds_list_mix[0].trstTypC
      this.edt_gdC = this.ds_list_mix[0].gdC
      this.edt_trstAmt = this.ds_list_mix[0].trstAmt
      this.edt_gdTypDtlC = this.ds_list_mix[0].gdTypDtlC
      this.edt_mngBrcd = this.ds_list_mix[0].mngBrcd
      this.edt_csId = this.ds_list_mix[0].csId
      this.edt_cnm = this.ds_list_mix[0].cnm
      this.edt_fundC = this.ds_list_mix[0].fundC
      this.edt_rcno = this.ds_list_mix[0].rcno
      this.edt_rcnoKindTc = this.ds_list_mix[0].rcnoKindTc
      this.edt_soptTc = this.ds_list_mix[0].soptTc
      this.edt_gdNm = this.ds_list_mix[0].gdNm
      
      if ( this.popCls == "2" ||  this.popCls == "3" ) {
        if ( !TSCommUtil.gfn_isNull(this.ds_list_mix[0].admkNm)) {
          this.edt_cnm = this.ds_list_mix[0].cnm + "[" + this.ds_list_mix[0].admkNm + "]" 
        }
        this.edt_bnkbAccYn = "Y"  
      }else if(this.popCls == "4"){
        if(svcID == "selectTPUP95090ListB"){
          if ( !TSCommUtil.gfn_isNull(this.ds_list_mix[0].bnkb)) { //등록된 계좌가 있는지 
            this.edt_bnkbAccYn = "Y" //등록된 계좌있음
            this.fn_transaction(this.Edt_acno);
          }else{
            this.edt_bnkbAccYn = "N" //등록된 계좌없음
          }
        }else{
          this.edt_bnkbAccYn = "Y" //등록된 계좌있음
        }
        if ( !TSCommUtil.gfn_isNull(this.ds_list_mix[0].admkNm)) {
          this.edt_cnm = this.ds_list_mix[0].cnm + "[" + this.ds_list_mix[0].admkNm + "]" 
        }
      }
      
      if ( !TSCommUtil.gfn_isNull(this.edt_accSeq)) { // 계좌일련번호가 존재하면 바로 세팅 
        this.Cbo_accSeq = this.edt_accSeq
        return ;
      }
      else {
        this.Cbo_accSeq = this.ds_list_mix[0].accSeq // this.Cbo_accSeq.set_index(0);
      }
        
      // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        try{
          if ( this.passYn == "Y" ) {
            console.lg('acctCallBackFuncTacno 해당 함수')
            // this.lookupFunc("acctCallBackFuncTacno").call(); // 존재하지 않음....
            this.acctCallBackFuncTacno()
          }
          else {
            // this.lookupFunc("acctCallBackFunc").call();
            this.acctCallBackFunc()
          }
        } catch(e){
        }	
      }
      
    },

    /************************************************************************************************
    * 콤보박스 변경 이벤트 
    ************************************************************************************************/
    Cbo_accSeq_onitemchanged ()
    {
      for ( let idx in this.ds_list_mix ) {
        if ( this.Cbo_accSeq == this.ds_list_mix[idx].accSeq ) {
          console.log(this.ds_list_mix[idx] )

          // 콤보값이 변경될때마다 데이터 변경 
          this.edt_curC = this.ds_list_mix[idx].curC
          this.cal_contDate = this.ds_list_mix[idx].contDate
          this.cal_mtrtDate = this.ds_list_mix[idx].mtrtDate
          this.edt_brigAccYn = this.ds_list_mix[idx].brigAccYn
          this.edt_trstTypC = this.ds_list_mix[idx].trstTypC
          this.edt_gdC = this.ds_list_mix[idx].gdC
          this.edt_trstAmt = this.ds_list_mix[idx].trstAmt
          this.edt_gdTypDtlC = this.ds_list_mix[idx].gdTypDtlC
          this.edt_mngBrcd = this.ds_list_mix[idx].mngBrcd
          this.edt_csId = this.ds_list_mix[idx].csId
          this.edt_fundC = this.ds_list_mix[idx].fundC
          this.edt_rcno = this.ds_list_mix[idx].rcno
          this.edt_rcnoKindTc = this.ds_list_mix[idx].rcnoKindTc
          this.edt_soptTc = this.ds_list_mix[idx].soptTc
          this.edt_gdNm = this.ds_list_mix[idx].gdNm
          break
        }
      }

      // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 	
      if ( this.ds_list_mix.length > 0 ) {
        try{
          this.fn_searchList() // this.lookupFunc("acctCallBackFunc").call();
        } catch(e){
        }	
      }
    },

    /************************************************************************************************
    * 데이터 변경시 계좌일련번호 콤보와 고객명 초기화 
    * TODO : 적용해야함
    ************************************************************************************************/
    Edt_acno_ontextchange ()
    {
      if ( TSCommUtil.gfn_length(this.ds_list_mix) > 0 ) {
        this.ds_list_mix.clearData();	
      }
      
      if ( TSCommUtil.gfn_length(this.ds_msData) > 0 ) {
        this.ds_msData.clearData();	
      }
    },

    /************************************************************************************************
    * 통장 리드 후 
    ************************************************************************************************/
    pbprCallBack ()
    {
    },   

    /************************************************************************************************
    * 통장 ms 데이터 전송
    ************************************************************************************************/
    _getMsData ()
    {
      return '' // 2021-08-17 add
    },  

    /************************************************************************************************
    * 통장 사용여부 
    ************************************************************************************************/
    _getMsUseYn ()
    {
      return '' // 2021-08-17 add
    }, 
    
    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 계좌조회 
     ******************************************************************************/
    fn_OpenPopup(type, flag) {
      switch (type) {
        case 0:
          this.$refs.popup206.fn_Open()
          break          
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : 계좌조회(206p) 컴포넌트에서 callback
     * 메인으로 처리함
     ******************************************************************************/
    fn_Popup_CallBack ( pData ) {
      console.log(pData)
      
      if(!TSCommUtil.gfn_isNull(pData.tacno)){
        Object.assign(this.DatasetTemp, defaultDatasetTemp),
        // this.DatasetTemp.loadXML(strVal);
        this.Edt_acno = pData.tacno + pData.bnkbSeq
        this.edt_accSeq = pData.accSeq
        this.fn_transaction(); // 조회 처리 
      }	
      
    },

  }

}

