/*
 * 업무구분: 책임자승인요청
 * 화 면 명: MSPTS106P
 * 화면설명: 책임자승인요청
 * 작 성 일: 2023.03.16
 * 작 성 자: 김경태 (전면개정 -> refined by anrachi) 
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="책임자승인요청">
    <div class="wrap-modalcontents">
      <div v-if="visAml" class="wrap-table-title row mt0 mb6 justify-between">
        <h2 class="table-title"> 계약번호 <strong class="colorB"> {{acno}} </strong> </h2>
        <div>
          <label> 화면구분 </label>
          <mo-dropdown :items="amlItems" v-model="aml" class="w130" disabled ref="dropdown1" />
        </div>
      </div>
      <div v-if="visAml" class="wrap-table mt-0">
        <table class="table row-type">
          <thead>
            <!-- 
            <tr>
              <th colspan="2"> 진행상태 </th>
              <th>  AML결과값 </th>
              <th> 신원검증 </th>
              <th> PEP </th>
            </tr>
            <tr>
              <th> 고객명 </th>
              <th> 실명번호 </th>
              <th> 위험등급 </th>
              <th> W/L대사(동일인확인) </th>
              <th> EDD </th>
            </tr>
            // -->
            <tr>
              <th>
                <table class="table row-type">
                  <tr>
                    <th colspan="2" class="w250"> 진행상태 </th>
                    <th class="w130">  AML결과값 </th>
                    <th class="w250"> 신원검증 </th>
                    <th class="w130"> PEP </th>
                  </tr>
                  <tr>
                    <th class="w100"> 고객명 </th>
                    <th class="w150"> 실명번호 </th>
                    <th class="w130"> 위험등급 </th>
                    <th class="w250"> W/L대사(동일인확인) </th>
                    <th class="w130"> EDD </th>
                  </tr>
                </table>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- 
            <template v-for="(data, idx) in tftarlList" >
              <tr :key="idx.toString()">
                <td colspan="2"> {{data.nowStatTypeNm}}</td>
                <td> {{data.amlRstValTcNm}} </td>
                <td> {{data.idtyVrfcYn}} </td>
                <td> {{data.pepRegYn}} </td>
              </tr>
              <tr :key="idx.toString()+1">
                <td> {{data.cnm}} </td>
                <td> {{data.rcno}} </td>
                <td v-if="data.rskType === "9'"> </td>
                <td v-else-if="data.rskType !== '9'"> {{data.rskType}} </td>
                <td v-if="data.wlNvtnYn === '9'"> </td>
                <td v-else-if="data.wlNvtnYn !== '9'"> {{data.wlNvtnYn}} </td>
                <td v-if="data.eddRegYn === '9'"> </td>
                <td v-else-if="data.eddRegYn !== '9'"> {{data.eddRegYn}} </td>
              </tr>
            </template>
            // -->
            <tr v-for="(data, idx) in tftarlList" :key="idx.toString()">
              <td>
                <table class="table row-type">
                  <tr>
                    <td colspan="2" class="w250"> {{data.nowStatTypeNm}} </td>
                    <td class="w130"> {{data.amlRstValTcNm}}  </td>
                    <td class="w250"> {{data.idtyVrfcYn}} </td>
                    <td class="w130"> {{data.pepRegYn}} </td>
                  </tr>
                  <tr>
                    <td class="w100"> {{data.cnm}} </td>
                    <td class="w150"> {{data.rcno}} </td>
                    <td v-if="data.rskType === '9'"> </td>
                    <td class="w130" v-else-if="data.rskType !== '9'"> {{data.rskType}} </td>
                    <td v-if="data.wlNvtnYn === '9'"> </td>
                    <td class="w250" v-else-if="data.wlNvtnYn !== '9'"> {{data.wlNvtnYn}} </td>
                    <td v-if="data.eddRegYn === '9'"> </td>
                    <td class="w130" v-else-if="data.eddRegYn !== '9'"> {{data.eddRegYn}} </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="wrap-table-title row mt0">
        <h2 class="table-title"> 승인자선택 </h2>
      </div>
      <div class="wrap-table mt10 maxh162 h-scroll">
        <table class="table col-type num-type th-row-gray text-center">
          <thead>
            <tr>
              <th class="w40"> </th>
              <th> 결재자명 </th>
              <th> 직위 </th>
              <th> 소속기관 </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, idx) in admissionUtilList" :key="idx.toString ()">
              <td @click="fn_RowSelected (idx.toString ())">
                <!-- <mo-radio 
                  :id="idx.toString ()" 
                  :value="idx" 
                  @click="fn_RowSelected (idx.toString ())" 
                /> -->
                <mo-radio 
                  v-model="lv_SelectedItem"
                  :id="idx.toString ()" 
                  :value="idx.toString ()" 
                  @click="fn_RowSelected (idx.toString ())" 
                />
              </td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.admEmpNm}}</td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.userGrpNm}}</td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.brnm}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="wrap-table-title row">
        <h2 class="table-title"> 승인요청메시지 </h2>
        <span class="red"> ※ 당일 투자성향 재진단건은 책임자 승인대상건으로, "재진단 사유"를 반드시 기재하셔야 합니다. </span>
      </div>
      <div class="wrap-input">
        <mo-text-area v-model="aprvRsn" placeholder="입력하세요." class="w100p" />
      </div>
      <div class="wrap-result row mt16">
        <div class="left w50p">
          <p class="triangle">승인요청번호 <span> {{ rstNo }} </span> </p>
        </div>
        <div class="left w50p">
          <p class="triangle text-result">승인결과 <span> {{ rstResult }} </span> </p>
        </div>
      </div>
      <p class="desc02 mt10"> ※ 책임자가 [4600]화면에서 책임자 승인을 처리할 수 있습니다. </p>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" :disabled="btnConfirmDisabled" @click="fn_Confirm">승인요청</mo-button>
      </div>
    </template>
    <!-- <ts-alert-popup 
      ref="alertPopup"
      :alertPopupObj="pAlertPopupObj"
    ></ts-alert-popup> -->
    <ts-alert-popup 
      ref="alertPopup_106p"
      :popupObj="pAlertPopupObj_160p"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>
  </mo-modal>
</template>

<script>
/******************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************************/
import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil"
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

export default {
  /************************************************************************************************
   * Vue 인스턴스 기본속성 정의 (화면명, ID 등)
   ************************************************************************************************/
  name: "MSPTS106P", // 현재 화면명
  screenId: "MSPTS106P", // 현재 화면 ID
  /***************************************************************************************************
   * Components 설정 영역
   ***************************************************************************************************/
  components: {
    "ts-alert-popup": TSAlertPopup,
  },
  /***************************************************************************************************
   * Props 설정 영역
   ***************************************************************************************************/
  props: {
    pPage: String, // 부모 페이지명
    // brcd: String,
    mngAprvSeq: String,
    // fileName: String,
    // admGrade: String,
    // reqEmno: String,
    // amlYn: String,
    tacno: String,
    bnkbSeq: String,
    ctno: String,
    procSeq: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /***************************************************************************************************
   * Data 설정 영역
   ***************************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // 공통 변수
      isInit: false, // 초기화 여부
      firstYn: "",
      successYn: "",
      lv_Params: {},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      // lv_SelectedItem: {},
      lv_SelectedItem: "0",
      lv_checkedItem: false,
      isSucs: false, // 리턴값 세팅여부
      disabedType: false,
      acno: "",
      contNo: "",
      aml: "",
      aprvRsn: "",
      rstNo: "",
      rstResult: "",
      procDate: "",
      brcd: "",
      fileName: "",
      reqEmno: "",
      admGrade: "",
      amlYn: "",
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      pAlertPopupObj: {},
      pAlertPopupObj_160p: {},
      visAml: false,
      amlItems: [],
      tftarlList: [],
      admissionUtilList: [],
      admissionUtilRst: [],
      tftaplRst: {},
      btnConfirmDisabled: true,
      successYn: "",
      // firstYn: "Y",
      timerInterval: "",
    }
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /***************************************************************************************************
   * Computed 설정 영역
   ***************************************************************************************************/
  computed: {
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
  },
  /***************************************************************************************************
   * Watch 설정 영역
   ***************************************************************************************************/
  watch: {
    //
  },
  /***************************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ***************************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    this.userInfo = this.getStore ("userInfo").getters.getUserInfo;
    this.fn_ComCode ();
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /***************************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ***************************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS106P");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /***************************************************************************************************
   * Methods 설정 영역
   ***************************************************************************************************/
  methods: {
    // ======================================================
    // (공통: 비동기 서비스 호출) fn_ComCode:: 
    //   code      : 호출할 공통코드
    //   dsName    : 호출된 공통코드를 담을 Dataset
    //   selecttype: ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
    //   selecttype은 추후 변경될 수 있음.
    //   this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    // ======================================================
    fn_ComCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        "TUKEMK": [{
          cId: "5045",
      },],
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600046_S' |
      // 'UF10600046' // [NEXT 사랑On 신탁]콤보 목록을 조회-selectComboList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS90S1"; // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [콤보 목록을 조회---5045]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------
      // ※ 원격 트랜잭션 서비스(EAI-PO)호출 API 변경 적용, modified by anarchi (2023.07.18)
      //   TSServiceUtil.invoke → TSServiceUtil.invokeInDirect
      // ------------------
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_ComCodeCallBack:: 
    // ======================================================
    fn_ComCodeCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [콤보 목록을 조회---5045]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      for (let index in res.data.tukemk) {
          let item = res.data.tukemk[index];
          switch (item.cid) {
            case "5045":
              this.amlItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
          }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:초기화) fn_Init:: 
    // ======================================================
    fn_Init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      // jjh 추가
      this.successYn = "";
	    this.firstYn = "Y";
      this.rstResult = "";
      if (!_.isEmpty (this.lv_Params.pProcDate)) {
        this.procDate = this.lv_Params.pProcDate;
        this.brcd = this.lv_Params.pBrcd;
        this.rstNo = this.lv_Params.pMngAprvSeq;
        this.fileName = this.lv_Params.pFileName;
        this.reqEmno = this.lv_Params.pReqEmno;
        this.admGrade = this.lv_Params.pAdmGrade;
        this.amlYn = this.lv_Params.pAmlYn;
        if (this.lv_Params.pAmlYn === "Y") {
          this.visAml = true;
          this.acno = this.lv_Params.pTacno + "-" + this.lv_Params.pBnkbSeq + "-" + this.lv_Params.pCtno;
          // ---------
          // □ 원격트랜잭션서비스(PO) 호출
          // 'C392_F10600195_S' |
          // 'UF10600195' // [NEXT 사랑On 신탁]계약_AML계약관계자내역 목록조회-selectTPUP95020List
          // ---------
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "S";
          // this.eaiCommObj.trnstId = "selectTPUP95020List";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600195_S";
          let pParams = {
            tacno: this.lv_Params.pTacno,
            bnkbSeq: this.lv_Params.pBnkbSeq,
            ctno: this.lv_Params.pCtno,
            procSeq: this.lv_Params.pProcSeq,
          };
          this.eaiCommObj.params = pParams;
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [계약_AML계약관계자내역 목록조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
          if (this.isLoggable) console.log ("+++++++++");
          TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_InitSearchResult, function (error) {
            if (this.isLoggable) console.log ("C392_F10600195_S ERRROR 발생!", error);
          }, "UF10600195");
        }
        this.fn_Search ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_InitSearchResult:: 조회 후처리
    // ======================================================
    fn_InitSearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_InitSearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [계약_AML계약관계자내역 목록조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      if (res.data.tftarl && 0 < res.data.tftarl.length) {
        // this.aml = res.data.body.tftarlList[0].amlScrnTc;
        this.tftarlList = res.data.tftarl;
        this.aml = this.tftarlList[0].amlScrnTc;
        // this.fn_RowSelected ("0");
        // this.fn_RowSelected (0);
        // this.rstNo = this.tftarlList[0].mngAprvSeq;
        // this.fn_Search ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_InitSearchResult ]___[ 종료 ]");
    },
    // ======================================================
    // (화면 제어) fn_Open:: 
    // ======================================================
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ [전달받은 파라미터]", " param ===> ", param);
      if (this.isLoggable) console.log ("+++++++++");
      this.lv_Params = param;
      this.fn_Init (); // onload시 초기화
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    // ======================================================
    // (화면 제어) fn_Close:: 책임자승인창 닫기
    // ======================================================
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      // debugger;
      // ------------------
      // 'R': 승인요청상태 | 'Y': 승인완료 | 'N': 승인반려
      // ------------------
      if (_.isEmpty (this.successYn) || this.successYn === "R") {
        let t_popupObj = {
          confirm: true,
          content: [
            "승인완료 팝업이 뜨기 전\n",
            "현재 창을 닫으실 경우\n",
            "승인이 정상처리 되지 않을 수 있습니다.\n",
            "\n",
            "그래도 닫으시겠습니까?",
          ],
          // content: " \
          //   승인완료 팝업이 뜨기 전\n \
          //   현재 창을 닫으실 경우\n \
          //   승인이 정상처리 되지 않을 수 있습니다. \
          //    \
          //    \
          //    \
          //   그래도 닫으시겠습니까? \
          // ",
          confirmFunc: this.fn_CloseConfirm,
          // closeFunc: this.fn_Close,
        };
        // this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
      }
      this.fn_Clear ();
      this.modal.close ();
      // this.closeDropdown ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ======================================================
    // (화면 제어) closeDropdown:: 
    // ======================================================
    closeDropdown () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::closeDropdown ]___[ 시작 ]");
      this.$refs.dropdown1.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::closeDropdown ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_CloseConfirm:: 승인 미요청건 삭제 처리-deleteTPUP95470 확인 닫기
    // ======================================================
    fn_CloseConfirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_CloseConfirm ]___[ 시작 ]");
      // debugger;
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [닫기전 (기존) 타이머 인스턴스 소거-시작]", " this.timerInterval ===> ", this.timerInterval);
      clearInterval (this.timerInterval);
      this.timerInterval = -1;
      if (this.isLoggable) console.log ("+ ▶▷ [닫기전 (기존) 타이머 인스턴스 소거-완료]", " this.timerInterval ===> ", this.timerInterval);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------------------------------------------------------------
      if (_.isEmpty (this.successYn)) {
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600218_S' |
        // 'UF10600218' // [NEXT 사랑On 신탁]승인 미요청건 삭제 처리-deleteTPUP95470
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "deleteTPUP95470";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600218_S";
        let pParams = {
          procDate: this.procDate,
          procBrcd: this.brcd,
          aprvNo: this.rstNo,
        };
        this.eaiCommObj.params = pParams;
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [승인 미요청건 삭제 처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_CloseConfirmResult, function (error) {
          if (this.isLoggable) console.log (error);
        }, "UF10600218");
      }
      // this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_CloseConfirm ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_CloseConfirmResult:: 책임자승인 처리(성공) 후처리
    // ======================================================
    fn_CloseConfirmResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_CloseConfirmResult ]___[ 시작 ]");
      // debugger;
      if (undefined != res.commHeaderVO && !TSCommUtil.gfn_isNull (res.commHeaderVO)) {
        if (this.isLoggable) console.log ("+ ▶▷ [CASE1--승인 미요청건 삭제 처리]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)리턴객체]", " res → ", res);
      }
      else {
        if (this.isLoggable) console.log ("+ ▶▷ [CASE2--책임자승인요청 팝업]_(닫기$콜백)리턴객체]", " res → ", res);
      }
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log ("+ ▶ [닫기 전, -(기존) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
      clearInterval (this.timerInterval);
      this.timerInterval = -1;
      if (this.isLoggable) console.log ("+ ▶▷ [닫기 전, -(기존) 타이머 인스턴스- 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
      // ---------------------------------------------------------------
      this.$emit ("ts-popup106-callback", this.successYn);
      this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_CloseConfirmResult ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:초기화) fn_Clear:: 입력값 전체 초기화 (초기값으로 셋팅)
    // ======================================================
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      // ---------------------------------------------------------------
      // ★★ 승인처리 확인 후, 타이머 인스턴스 제거 및 관련 변수 초기화
      //   corrected & refined by anarchi (2023.07.29)
      // ---------------------------------------------------------------
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [승인처리 후, -(현재) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
      clearInterval (this.timerInterval);
      this.timerInterval = -1;
      if (this.isLoggable) console.log ("+ ▶▷ [승인처리 후, -(현재) 타이머 인스턴스- 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------------------------------------------------------------
	    this.firstYn = "";
      this.rstResult = "";
      this.successYn = "";
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    // ======================================================
    // (화면제어) fn_AlertPopup:: 공통팝업호출 처리오류 코드 전면수정, corrected by anarchi (2023.08.02)
    // ======================================================
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [로컬_메시지팝업_처리용]", " type → ", type, " pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      // this.$emit ("ts-alert-popup", type, pData);
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_160p, pPopupObj);
          }
          this.$refs.alertPopup_106p.fn_Open ();
          break;
        case 2:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (0, this.pAlertPopupObj_160p, pPopupObj);
          }
          this.$refs.alertPopupSec.fn_Open ();
          break;
        case 7: // 마지막 얼럿창
          this.$refs.alertPopup_106p.fn_Open ();
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_160p, pPopupObj);
          }
          this.$refs.alertPopup_106p.fn_Open ();
          break;
        default:
          this.$refs.alertPopup_106p.fn_Open ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_Search:: 승인_요청자 목록조회-selectTPUP95470List
    // ======================================================
    fn_Search () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      // debugger;
      // ---------
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600219_S' |
      // 'UF10600219' // [NEXT 사랑On 신탁]승인_요청자 목록조회-selectTPUP95470List
      // ---------
      this.eaiCommObj = TSCommUtil.gfn_eaiCommObj ();
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "selectTPUP95470List";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600219_S";
      let pParams = {
        reqEmpno: TSCommUtil.gfn_right (this.lv_Params.pReqEmno, 5),
        admGrade: this.lv_Params.pAdmGrade,
      };
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [승인_요청자 목록조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600219");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_SearchResult:: 승인_요청자 목록조회 후처리
    // ======================================================
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [승인_요청자 목록조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      this.admissionUtilList = res.data.admissionUtil;
      if (this.admissionUtilList.length < 1) {
        let t_popupObj = {
          single: true,
          confirm: false,
          content: "전송할 책임자요청\n데이타가 존재하지 않습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.btnConfirmDisabled = true;
        return;
      }
      this.btnConfirmDisabled = false;
      this.fn_RowSelected ("0");
      // for (let i = 0; i < this.admissionUtilList.length; i++) {
      //   if (this.admissionUtilList[i].admEmp === this.reqEmno) {
      //     this.admissionUtilList[i].chkYn = "1";
      //   }
      // }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_Confirm:: 승인요청처리-updateTPUP95470A
    // ======================================================
    fn_Confirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Confirm ]___[ 시작 ]");
      // debugger;
      if (_.isEmpty (this.rowSelect)) {
        let t_popupObj = {
          single: true,
          confirm: false,
          content: "책임자를 선택해 주십시요.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (this.aprvRsn.length > 200) {
        let t_popupObj = {
          single: true,
          confirm: false,
          content: ["승인요청 메시지는", "최대 300자(한글100자) 입니다."],
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷ [선택된 책임승인자]", " this.rowSelect ===> ", this.rowSelect);
      // if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // PO서비스 호출 파라미터 설정
      // ---------
      let pParams = {};
      // for (var i = 0; i < this.admissionUtilList.length; i++) {
      //   if (this.admissionUtilList[i].chkYn === "1") {
      //     pParams.push ({
      //       admEmp: this.admissionUtilList[i].admEmp,
      //     });
      //   }
      // }
      // if (_.isEmpty (this.rowSelect)) {
      // }
      pParams.admEmp = TSCommUtil.gfn_isNull ( this.rowSelect.admEmp ) ? "" : this.rowSelect.admEmp;
      pParams.fileName = TSCommUtil.gfn_isNull ( this.fileName ) ? "" : this.fileName;
      pParams.procDate = TSCommUtil.gfn_isNull ( this.procDate ) ? "" : this.procDate;
      pParams.procBrcd = TSCommUtil.gfn_isNull ( this.brcd ) ? "" : this.brcd;
      pParams.aprvNo = TSCommUtil.gfn_isNull ( this.rstNo ) ? "" : this.rstNo;
      pParams.admGrade = TSCommUtil.gfn_isNull ( this.admGrade ) ? "" : this.admGrade;
      pParams.aprvRsn = TSCommUtil.gfn_isNull ( this.aprvRsn ) ? "" : this.aprvRsn;
      pParams.amlYn = TSCommUtil.gfn_isNull ( this.amlYn ) ? "" : this.amlYn;
      let lvTacno = TSCommUtil.gfn_isNull ( this.lv_Params.pTacno ) ? "" : this.lv_Params.pTacno;
      let lvBnkbSeq = TSCommUtil.gfn_isNull ( this.lv_Params.pBnkbSeq ) ? "" : this.lv_Params.pBnkbSeq;
      let lvCtno = TSCommUtil.gfn_isNull ( this.lv_Params.pCtno ) ? "" : this.lv_Params.pCtno;
      let lvProcSeq = TSCommUtil.gfn_isNull ( this.lv_Params.pProcSeq ) ? "" : this.lv_Params.pProcSeq;
      pParams.amlCntt = lvTacno + lvBnkbSeq + lvCtno.padStart (3, "0") + lvProcSeq;
      this.successYn = "R";
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600220_S' |
      // 'UF10600220' // [NEXT 사랑On 신탁]승인요청처리-updateTPUP95470A
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "updateTPUP95470A";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600220_S";
      this.eaiCommObj.params = {
        AdmissionUtil : [pParams], 
      };
      Object.assign (this.eaiCommObj.params, pParams);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [승인요청처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ConfirmResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600220");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_Confirm ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_ConfirmResult:: 승인요청처리 후처리 (승인요청확인 타이머 설정)
    // ======================================================
    fn_ConfirmResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_ConfirmResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [승인요청처리]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      // this.admissionUtilRst = res.data.admissionUtilRst;
      // const resData = res.data;
      // this.pAlertPopupObj = {
      //   single: true,
      //   confirm: false,
      //   closeFunc: false,
      //   content: "책임자 승인요청하였습니다.",
      // };
      // this.$emit ("ts-alert-popup", 0, t_popupObj);
      // this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
      // ---
      // ★☆
      // ---
      this.fn_AlertPopup (0, {
        single: true,
        confirm: false,
        content: "책임자 승인요청하였습니다.",
      });
      // ---
      // ★
      // ---
      if (res.data.aprvProcGb === "0") {
        this.rstResult = "승인요청";
        this.btnConfirmDisabled = true;
        // ---------------------------------------------------------------
        // ★★ 신규 타이머 (인스턴스 업무처리 정합성 및 동기화 관리 로직 추가) 생성
        //   corrected & refined by anarchi (2023.07.29)
        // ---------------------------------------------------------------
        if (!TSCommUtil.gfn_isEmpty (this.timerInterval) && -1 != this.timerInterval) {
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [신규생성 전, -(기존) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
          clearInterval (this.timerInterval);
          this.timerInterval = -1;
          if (this.isLoggable) console.log ("+ ▶▷ [신규생성 전, -(기존) 타이머 인스턴스 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
          if (this.isLoggable) console.log ("+++++++++");
        }
        this.timerInterval = setInterval (this.fn_TPUP95470_ontimer, 10000);
        // ---------------------------------------------------------------
        // ------------------
        // MSPTS213M/MSPTS204P/MSPTS106P 송금계좌등록/해지>책임자승인요청 관련 기능 미구현 및 오류
        // ★★★ 비표준 API 사용 및 미정의 함수 처리부분, corrected by anarchi (2023.07.18)
        // ------------------
        // this.setTimer (3, 15000);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_ConfirmResult ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_TPUP95470_ontimer:: 승인_요청자 상세조회-selectTPUP95460
    // ======================================================
    fn_TPUP95470_ontimer () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_TPUP95470_ontimer ]___[ 시작 ]");
      // debugger;
      // ------------------
      // tftaplRst.aprvStatTc 승인상태값
      // '11': 승인요청 | '12': 승인완료 | '13': 승인반려
      // ------------------
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ☆ [승인상태]", " this.tftaplRst.aprvStatTc ===> ", this.tftaplRst.aprvStatTc);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.tftaplRst.aprvStatTc !== "12" || this.tftaplRst.aprvStatTc !== "13") {
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600217_S' |
        // 'UF10600217' // [NEXT 사랑On 신탁]승인_요청자 상세조회-selectTPUP95460
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "selectTPUP95460";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600217_S";
        let pParams = {
          procDate: this.procDate,
          procBrcd: this.brcd,
          aprvNo: this.rstNo,
        };
        this.eaiCommObj.params = pParams;
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [승인_요청자 상세조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_TPUP95470_ontimerResult, function (error) {
          if (this.isLoggable) console.log (error);
        }, "UF10600217");
      }
      else {
        // ---------------------------------------------------------------
        // 승인처리결과확인 타이머 인스턴스 확인
        // ---------------------------------------------------------------
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ☆☆ [승인상태확인 -(현재) 타이머 인스턴스- 확인]", " this.timerInterval ===> ", this.timerInterval);
        if (this.isLoggable) console.log ("+++++++++");
        // ---------------------------------------------------------------
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_TPUP95470_ontimer ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 콜백) fn_TPUP95470_ontimerResult:: 승인_요청자 상세조회 후처리
    // ======================================================
    fn_TPUP95470_ontimerResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_TPUP95470_ontimerResult ]___[ 시작 ]");
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [승인_요청자 상세조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)리턴객체]", " res → ", res);
        if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      const resData = Object.assign ({}, res.data); // 초기화
      if (TSCommUtil.gfn_trim (resData.errorCode) != "") {
        if (this.isLoggable) console.log ("+ ▶▷ resData.errorCode ===> ", resData.errorCode);
        // ---------------------------------------------------------------
        // ★★ 승인처리확인 오류발생 후, 타이머 인스턴스 제거
        //   corrected & refined by anarchi (2023.08.03)
        // ---------------------------------------------------------------
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷▷ [승인처리실패 후, -(현재) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
        clearInterval (this.timerInterval);
        this.timerInterval = -1;
        if (this.isLoggable) console.log ("+ ▶▷▷▷ [승인처리실패 후, -(현재) 타이머 인스턴스- 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
        if (this.isLoggable) console.log ("+++++++++");
        // ---------------------------------------------------------------
        this.fn_AlertPopup (0, {
          single: true,
          confirm: false,
          content: "승인처리중 오류가 발생하였습니다.",
        });
        this.rstResult = "승인오류";
        this.successYn = "";
        return false;
      }
      Object.assign (this.tftaplRst, res.data); // 결과값 할당
      if (resData.aprvStatTc === "12") { // 승인처리
        // ---------------------------------------------------------------
        // ★★ 승인처리확인 후, 타이머 인스턴스 제거
        //   corrected & refined by anarchi (2023.07.29)
        // ---------------------------------------------------------------
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷▷ [승인처리완료 후, -(현재) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
        clearInterval (this.timerInterval);
        this.timerInterval = -1;
        if (this.isLoggable) console.log ("+ ▶▷▷▷ [승인처리완료 후, -(현재) 타이머 인스턴스- 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
        if (this.isLoggable) console.log ("+++++++++");
        // ---------------------------------------------------------------
        this.rstResult = "승인완료";
        this.successYn = "Y";
        // ---
        // ★☆☆
        // ---
        // let t_popupObj = {
        //   confirm: false,
        //   single: true,
        //   content: "승인처리가 완료 되었습니다.",
        // };
        // this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, {
          single: true,
          confirm: false,
          content: "승인처리가 완료 되었습니다.",
        });
        this.fn_CloseConfirmResult (this.successYn);
      }
      else if (resData.aprvStatTc === "13") { // 반려처리
        // ---------------------------------------------------------------
        // ★★ 승인처리확인 후, 타이머 인스턴스 제거
        //   corrected & refined by anarchi (2023.07.29)
        // ---------------------------------------------------------------
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷▷ [승인반려 후, -(현재) 타이머 인스턴스- 소거_시작]", " this.timerInterval ===> ", this.timerInterval);
        clearInterval (this.timerInterval);
        this.timerInterval = -1;
        if (this.isLoggable) console.log ("+ ▶▷▷▷ [승인반려 후, -(현재) 타이머 인스턴스- 소거_완료]", " this.timerInterval ===> ", this.timerInterval);
        if (this.isLoggable) console.log ("+++++++++");
        // ---------------------------------------------------------------
        this.rstResult = "승인반려";
        this.successYn = "N";
        // let t_popupObj = {
        //   confirm: false,
        //   single: true,
        //   content: "승인반려 처리 되었습니다.",
        // };
        // this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, {
          single: true,
          confirm: false,
          content: "승인반려 처리 되었습니다.",
        });
        this.fn_CloseConfirmResult (this.successYn);
      }
      else { // 승인요청중
        // ---------------------------------------------------------------
        // 승인처리결과확인 타이머 인스턴스 확인
        // ---------------------------------------------------------------
        if (this.isLoggable) console.log ("+ ☆☆☆ [승인상태확인중 -(현재) 타이머 인스턴스 확인-]", " this.timerInterval ===> ", this.timerInterval);
        // ---------------------------------------------------------------
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_TPUP95470_ontimerResult ]___[ 종료 ]");
    },
    // ======================================================
    // (데이터 제어) fn_RowSelected:: 조회내역(목록) 선택 처리
    // ======================================================
    fn_RowSelected (idx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_RowSelected ]___[ 시작 ]");
      // debugger;
      this.lv_SelectedItem = idx;
      let findIdx = this.admissionUtilList.findIndex (obj => obj.chnlCustId === this.lv_SelectedItem);
      if (findIdx !== -1) {
        Object.assign (this.lv_SelectedItem, this.admissionUtilList[findIdx]);
      }
      this.rowSelect = this.admissionUtilList[idx];
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷▷ this.rowSelect ===> ", this.rowSelect);
      // if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS106P ]__[ METHODS:::fn_RowSelected ]___[ 종료 ]");
    },
    // ======================================================
    // (화면요소 제어--콜백) returnEvt:: 팝업 확인, 닫기 시
    // ======================================================
    returnEvt (value) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::returnEvt ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [알림창 emit 이벤트]", " value → ", value);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::returnEvt ]___[ 종료 ]");
    },
  }
}
</script>
