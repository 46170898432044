/*
 * 업무구분: 신탁>영업>최초신규고객>신규입력>송금계좌등록내역조회_P
 * 화 면 명: MSPTS321P
 * 화면설명: 송금계좌등록내역조회 POPUP
 * 작 성 일: 2023.05.23
 * 작 성 자: 지재호
 * 파 일 명: TFIO42102.xfdl
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal large noShadow" ref="modal" title="송금계좌등록내역조회">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"> </th>
                <th> 은행 </th>
                <th> 은행코드 </th>
                <th> 은행계좌 </th>
                <th> 계좌명 </th>
                <th> 등록일자 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_list" :key="idx" :class="{'checked': valueRadio == String(idx)}" @click="valueRadio = String(idx)">
                <td>
                  <mo-radio v-model="valueRadio" :value="`${String(idx)}`"  ></mo-radio>
                </td>
                <td>
                  {{row.trBkcdNm}}
                </td>
                <td>
                  {{ row.trBkcd }}
                </td>
                <td>
                  {{row.bnkAcno}}
                </td>
                <td>
                  {{row.bnkAccNm}}
                </td>
                <td>
                  {{$tsUtil.gfn_dateReplace(1, row.regDate)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close(1)">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm(1)" :disabled="valueRadio==null">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>

const defaultDsSearch = {
  csId: "",
};

const defaultDsList = {
  trBkcd: '',
  bnkAcno: '',
  bnkAccNm: '',
  trBkcdNm: '',
  regDate: '',
  stipSeq: '',
};

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS313P",
  screenId: "MSPTS313P",
  components: {},
  props: {
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS313P");
    this.fn_comCode()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      csId: '',
      ds_search: Object.assign({}, defaultDsSearch),
      ds_list: [],
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),       // Contents Model 
      ds_cmb5095: [], // 은행공통코드

      valueRadio: 0, // 리스트 라디오 버튼
      // infolookList: [
      //   {
      //     id: '1',
      //     data1: 'SC제일',
      //     data2: '023',
      //     data3: '43220061812',
      //     data4:'김삼성',
      //     data5:'2023-05-18',
      //   },
      // ],
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal;
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /************************************************************************************************
     * 모달오픈
     ************************************************************************************************/
    fn_Open() {
      this.fn_afterFormOnload()
      this.modal.open();
    },

    /************************************************************************************************
     * 닫기버튼
     ************************************************************************************************/
    fn_Close() {
      this.modal.close();
    },

    /************************************************************************************************
     * 데이터값 입력 
     ************************************************************************************************/
    fn_Confirm() {
      
      this.fn_save();
      this.modal.close();
    },
    fn_Init(){

    },
    onClickSearch(){
      this.$addSnackbar("click: searchIcon"); // test code
    },

    /***************************************************************************************
        실질적인 화면 초기화 
    *****************************************************************************************/
    fn_afterFormOnload ()
    {
      this.csId = ""  // 고객 ID
      this.valueRadio = "0" // 리스트 라디오 초기화
      if ( !TSCommUtil.gfn_isNull(this.popupObj.csId) ) { // 화면에서 값이 넘어온 경우에만 
        this.csId   = this.popupObj.csId // 고객 ID
        this.fn_searchBankAcno() // 송금계좌 정보 조회 
      }
    },

    /***************************************************************************************
      송금계좌 정보 조회   
    *****************************************************************************************/
    fn_searchBankAcno ()
    {
      //조회용 dataset에 조회할 항목을 넣어준다.
      Object.assign(this.ds_search, defaultDsSearch)
      this.ds_search.csId = this.csId
      
      // //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = "selectTFIO42100CList";
      // var sURL          = "svc::selectTFIO42100CList.do";

      // // f1 transaction 으로 검색해서 추가
      // var sInDatasets   = "tfio42100=ds_search:";
      // var sOutDatasets  = "ds_list=tfcbslList";
      // var sArgument     = "";
      // var sCallbackFunc = "";
      
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600164_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /***************************************************************************************
      송금계좌 정보 콜백
    *****************************************************************************************/
    fn_callBack ( res ) {
      this.ds_list = [] // ds_list 초기화

      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }

      if ( TSCommUtil.gfn_length(resData.tfcbsl) > 0 ) {
        this.ds_list = resData.tfcbsl
      } 
    },

    /***************************************************************************************
      확인처리 
    *****************************************************************************************/
    fn_save ()
    {
      let result = new Object();
      const idx = this.valueRadio
      result.trBkcd = this.ds_list[idx].trBkcd  // 거래은행코드  
      result.bnkAcno = this.ds_list[idx].bnkAcno  // 은행계좌
      result.bnkAccNm = this.ds_list[idx].bnkAccNm  // 은행계좌명
      result.trBkcdNm = this.ds_list[idx].trBkcdNm  // 거래은행명
      result.stipSeq = this.ds_list[idx].stipSeq  // 거래은행명 2021-11-23 add
      this.$emit("ts-popup-callback", 0, result);
    },

    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_comCode ()
    {
      this.eaiCommObj.lv_vm = this
      //this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5095"}     // ds_cmb5095 은행공통 코드 
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
    }, 

    fn_comCodeCallBack ( res ) {
      for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) 
          {
            case "5095":
              this.ds_cmb5095.push({value: item.c , text: item.cnm})
              break
          }
      }
    },

    /***************************************************************************************
      은행이름 처리
    *****************************************************************************************/
    fn_bankName (trBkcd)
    { 
      let rtn = ''
      for ( let idx in this.ds_cmb5095 ) {
        if ( this.ds_cmb5095[idx].value == trBkcd ) {
          console.log(this.ds_cmb5095[idx])
          console.log('끝')
          rtn = this.ds_cmb5095[idx].text
          return this.ds_cmb5095[idx].text
        }
      }
      // this.ds_cmb5095.forEach(element => {
      //   console.log(element)
      //   if (element.value==trBkcd){
      //     console.log(element)
      //     console.log('끝')
      //     rtn = element.text
      //     return false
      //   }
      // });

      // return rtn
    },

  }
};
</script>
<style scoped>
</style>